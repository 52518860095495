import React, { useEffect, useState } from "react";
import TopBar from "../../components/TopBar";
import ConnectRepositoryOverlayStep1 from "../../components/overlays/ConnectRepositoryOverlayStep1";
import ConnectRepositoryOverlayStep2 from "../../components/overlays/ConnectRepositoryOverlayStep2";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAvailableRemotes,
  getGithubRepo,
  getLanguages,
  postGithubCode,
  useGetAllRepositories,
  useGitHubCode,
  useGitHubRepo,
} from "../../api/repositories";
import {
  ProjectsRepoResponseType,
  RepoResponseType,
} from "../../types/repositories";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { getProject, useCreateProject } from "../../api/projects";
// import RepositoryCard from "../../components/RepositoryCard";
import WarningOverlay from "../../components/overlays/WarningOverlay";
import SelectLanguageOverlay from "../../components/overlays/SelectLanguageOverlay";
import { getMe } from "../../api/users";
import LoadingSpinner from "../../components/fields/LoadingSpinner";
import RepositoriesTable from "./components/RepositoriesTable";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { API } from "../../data";
import ProjectDropdown from "../../components/ui/ProjectDropdown";
import CreateProjectDialog from "../../components/ui/CreateProjectDialog";
import { toast } from "react-toastify";

const useGetProjects = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["projects"],
    queryFn: () => API.projects.listProjects(),
  });

  return { projects: data?.data || [], error, isLoading };
};

const CodeAuditPage = () => {
  const [isOpenStep1, setIsOpenStep1] = useState<boolean>(false);
  const [isOpenStep2, setIsOpenStep2] = useState<boolean>(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const [isLanguageModalOpen, setIsLanguageModalOpen] =
    useState<boolean>(false);
  const [isCreateProjectOpen, setIsCreateProjectOpen] =
    useState<boolean>(false);
  const [repositories, setRepositories] = useState<RepoResponseType[]>([]);
  let location = useLocation();
  const [selected, setSelected] = useState(repositories[0]);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [projectId, setProjectId] = useState<number>();
  const [languages, setLanguages] = useState<string[]>([]);
  const [selectedRepository, setSelectedRepository] =
    useState<ProjectsRepoResponseType>();

  const queryClient = useQueryClient();
  const { projects, isLoading: isLoadingProjects } = useGetProjects();

  useEffect(() => {
    if (projects.length > 0 && !projectId) {
      setProjectId(projects[0].id);
    }
  }, [projects, projectId]);

  useEffect(() => {
    getLanguages().then((res) => {
      setLanguages(res as string[]);
    });
  }, []);

  const handleClick = () => {
    getAvailableRemotes().then((res) => {
      if (res && res.length) {
        getGithubRepo(projectId as number).then((res) => {
          setRepositories(res as RepoResponseType[]);
          setIsOpenStep2(true);
        });
      } else {
        setIsOpenStep1(true);
      }
    });
  };

  const { mutateAsync: createProject } = useCreateProject();
  const handleCreateProject = async (projectName: string) => {
    await createProject({ name: projectName });
  };

  useEffect(() => {
    dispatch(getMe())
      .then((res) => {
        if (res.payload.id) {
          localStorage.setItem("userId", res.payload.id.toString());
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, [dispatch]);

  const { data: githubCode, isLoading: isLoadingGithubCode } = useGitHubCode(
    location.search.includes("remote=github")
      ? (new URLSearchParams(window.location.search).get("code") as string)
      : null
  );

  const { data: githubRepos, isLoading: isLoadingGithubRepos } = useGitHubRepo(
    githubCode?.data?.success || false,
    projectId as number
  );

  useEffect(() => {
    if (githubRepos?.data) {
      if (location.search.includes("remote=github")) {
        setRepositories(githubRepos.data);
        setIsOpenStep2(true);
      }
    }
  }, [githubRepos?.data, location.search]);

  const { repositories: repos, isLoading } = useGetAllRepositories(
    projectId as number
  );

  useEffect(() => {
    if (
      repos &&
      repos.length > 0 &&
      !location.search.includes("remote=github")
    ) {
      const convertedRepos = repos.map((repo) => ({
        id: repo.id,
        name: repo.repoName,
        connected: true,
      }));
      setRepositories(convertedRepos);
    }
  }, [repos, location.search]);

  console.log("repos", repos);

  return (
    <div className="bg-white min-h-screen flex items-stretch justify-start font-['Inter'] text-custom-900">
      <div className="mx-10 my-2 h-full w-full flex flex-col justify-start items-center">
        <TopBar />
        {repos && repos.length !== 0 && (
          <>
            <div className="w-full grid grid-cols-2 items-center mb-4">
              <div className="justify-self-start">
                <ProjectDropdown
                  projects={projects}
                  selectedProjectId={projectId}
                  onProjectChange={setProjectId}
                  onCreateProject={() => setIsCreateProjectOpen(true)}
                  className="w-64"
                />
              </div>
              <div className="justify-self-end">
                <div
                  className="bg-custom-900 hover:bg-gray-800 text-white w-60 h-10 p-2 rounded-md text-center cursor-pointer"
                  onClick={handleClick}
                >
                  Connect Repository
                </div>
              </div>
            </div>
            <div className="w-full flex flex-wrap items-center justify-center md:justify-start gap-4">
              <RepositoriesTable
                repositories={repos}
                setSelectedRepository={setSelectedRepository}
              />

              {/* {connectedRepositories.map((repository, index) => (
                <RepositoryCard
                  key={index}
                  repository={repository}
                  isConnected={true}
                  setIsWarningModalOpen={setIsWarningModalOpen}
                  setIsLanguageModalOpen={setIsLanguageModalOpen}
                  navigate={navigate}
                  setSelectedRepository={
                    setSelectedRepository as React.Dispatch<
                      React.SetStateAction<ProjectsRepoResponseType>
                    >
                  }
                />
              ))} */}
            </div>
          </>
        )}
        {isLoading && <LoadingSpinner />}
        {!isLoading && !!repos && repos.length === 0 && (
          <div className="flex flex-col items-center justify-center h-full max-w-xl mt-80">
            <ProjectDropdown
              projects={projects}
              selectedProjectId={projectId}
              onProjectChange={setProjectId}
              onCreateProject={() => setIsCreateProjectOpen(true)}
              className="w-64 mb-10 -mt-20"
            />
            <div className="font-semibold text-2xl text-center mb-2">
              To get started, connect your first repository
            </div>
            <div className="font-normal text-md text-center mb-4">
              By linking a repository, you enable our tools to dive deep into
              your code, identifying areas for improvement, potential errors,
              and security vulnerabilities.
            </div>
            <div
              className="bg-custom-900 hover:bg-gray-800 text-white w-60 h-10 p-2 rounded-md mt-6 text-center cursor-pointer"
              onClick={handleClick}
            >
              Connect Repository
            </div>
          </div>
        )}
      </div>
      {isOpenStep1 &&
        ConnectRepositoryOverlayStep1({ setIsOpenStep1, isOpenStep1 })}
      {isOpenStep2 &&
        ConnectRepositoryOverlayStep2({
          setIsOpenStep1,
          setIsOpenStep2,
          isOpenStep2,
          repositories,
          selected,
          setSelected,
          projectId: projectId || 0,
          navigate,
        })}
      {isWarningModalOpen && (
        <WarningOverlay
          isWarningModalOpen={isWarningModalOpen}
          setIsWarningModalOpen={setIsWarningModalOpen}
        />
      )}
      {isLanguageModalOpen && (
        <SelectLanguageOverlay
          isLanguageModalOpen={isLanguageModalOpen}
          setIsLanguageModalOpen={setIsLanguageModalOpen}
          languages={languages}
          id={selectedRepository?.id as number}
        />
      )}
      <CreateProjectDialog
        isOpen={isCreateProjectOpen}
        onClose={() => setIsCreateProjectOpen(false)}
        onSubmit={handleCreateProject}
      />
    </div>
  );
};

export default CodeAuditPage;
