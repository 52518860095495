import React, { useState, useRef, useEffect } from "react";
import {
  IconChevronDown,
  IconCheck,
  IconFolder,
  IconFolderPlus,
} from "@tabler/icons-react";

interface Project {
  id: number;
  name: string;
}

interface ProjectDropdownProps {
  projects: Project[];
  selectedProjectId?: number;
  onProjectChange: (projectId: number) => void;
  onCreateProject?: () => void;
  className?: string;
}

const ProjectDropdown: React.FC<ProjectDropdownProps> = ({
  projects,
  selectedProjectId,
  onProjectChange,
  onCreateProject,
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedProject = projects.find((p) => p.id === selectedProjectId);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const isActive = isFocused || isOpen || !!selectedProject;

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <div className="relative">
        <button
          type="button"
          className={`relative w-full h-10 bg-white pl-11 pr-10 text-left border rounded-lg cursor-pointer transition-all duration-200 focus:outline-none group
            ${
              isActive
                ? "border-custom-900 shadow-sm"
                : "border-gray-200 hover:border-gray-300"
            }`}
          onClick={() => {
            setIsOpen(!isOpen);
            setIsFocused(true);
          }}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
        >
          <span
            className={`absolute left-3 top-1/2 transform -translate-y-1/2 transition-colors duration-200 
            ${isActive ? "text-custom-900" : "text-gray-400"}`}
          >
            <IconFolder size={18} stroke={1.5} />
          </span>
          <span
            className={`block truncate text-sm transition-colors duration-200
            ${selectedProject ? "text-gray-900" : "text-gray-500"}`}
          >
            {selectedProject?.name || "Select a project"}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">
            <IconChevronDown
              size={16}
              stroke={1.5}
              className={`transform transition-transform duration-200 ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </span>
          <label
            className={`absolute text-xs font-medium tracking-wide transition-all duration-200
              ${
                isActive
                  ? "-top-2 left-2 px-1 text-custom-900 bg-white"
                  : "top-1/2 left-11 text-gray-400 -translate-y-1/2"
              }`}
          >
            Project
          </label>
        </button>

        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-100 py-1 max-h-60 overflow-auto focus:outline-none">
            {projects.map((project) => (
              <div
                key={project.id}
                className={`relative cursor-pointer select-none h-10 flex items-center pl-11 pr-9 text-sm hover:bg-gray-50 transition-colors duration-100
                  ${
                    project.id === selectedProjectId
                      ? "text-custom-900 bg-gray-50/70"
                      : "text-gray-700"
                  }`}
                onClick={() => {
                  onProjectChange(project.id);
                  setIsOpen(false);
                }}
              >
                <span className="absolute left-3 inset-y-0 flex items-center text-gray-400">
                  <IconFolder size={16} stroke={1.5} />
                </span>
                <span className="block truncate font-medium">
                  {project.name}
                </span>
                {project.id === selectedProjectId && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-custom-900">
                    <IconCheck size={16} stroke={2} />
                  </span>
                )}
              </div>
            ))}

            <div className="px-1 py-1 border-t border-gray-100 mt-1">
              <div
                className="relative cursor-pointer select-none h-10 flex items-center pl-11 pr-4 text-sm text-custom-900 hover:bg-gray-50 rounded-md transition-colors duration-100 group"
                onClick={() => {
                  if (onCreateProject) {
                    onCreateProject();
                    setIsOpen(false);
                  }
                }}
              >
                <span className="absolute left-3 inset-y-0 flex items-center text-custom-900">
                  <IconFolderPlus
                    size={16}
                    stroke={1.5}
                    className="transition-transform duration-200 group-hover:scale-110"
                  />
                </span>
                <span className="block truncate font-medium">
                  Create New Project
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectDropdown;
