/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  ListRepositoriesResponse,
  RepositoryOverview,
  RepositoryPrimaryLanguageResponse,
  VectorRepository,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Repositories<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get a list of repositories for the authenticated user
   *
   * @tags Repository
   * @name RepositoriesList
   * @summary List user repositories
   * @request GET:/repositories
   * @secure
   */
  repositoriesList = (
    query?: {
      /** Optional project ID to filter repositories */
      projectId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ListRepositoriesResponse, Error>({
      path: `/repositories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Delete a repository by its ID
   *
   * @tags Repository
   * @name RepositoriesDelete
   * @summary Delete a repository
   * @request DELETE:/repositories/{id}
   * @secure
   */
  repositoriesDelete = (id: number, params: RequestParams = {}) =>
    this.request<void, Error>({
      path: `/repositories/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Get the overview of a repository
   *
   * @tags Repository Overview
   * @name GetRepositoryOverview
   * @summary Get repository overview
   * @request GET:/repositories/{repositoryId}/overview
   * @secure
   */
  getRepositoryOverview = (repositoryId: string, params: RequestParams = {}) =>
    this.request<RepositoryOverview, Error>({
      path: `/repositories/${repositoryId}/overview`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Refresh the overview of a repository
   *
   * @tags Repository Overview
   * @name RefreshRepositoryOverview
   * @summary Refresh repository overview
   * @request POST:/repositories/{repositoryId}/overview/refresh
   */
  refreshRepositoryOverview = (repositoryId: number, params: RequestParams = {}) =>
    this.request<RepositoryOverview, Error>({
      path: `/repositories/${repositoryId}/overview/refresh`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * @description Get the primary programming language of a repository
   *
   * @tags Repository
   * @name PrimaryLanguageDetail
   * @summary Get repository primary language
   * @request GET:/repositories/{repositoryId}/primary-language
   */
  primaryLanguageDetail = (repositoryId: number, params: RequestParams = {}) =>
    this.request<RepositoryPrimaryLanguageResponse, Error>({
      path: `/repositories/${repositoryId}/primary-language`,
      method: "GET",
      ...params,
    });
  /**
   * @description Get the vector repository for a repository
   *
   * @tags Repository
   * @name GetVectorRepository
   * @summary Get vector repository
   * @request GET:/repositories/{repositoryId}/vector-repository
   * @secure
   */
  getVectorRepository = (repositoryId: any, params: RequestParams = {}) =>
    this.request<VectorRepository, Error>({
      path: `/repositories/${repositoryId}/vector-repository`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * @description Sync the vector repository for a repository
   *
   * @tags Repository
   * @name SyncVectorRepository
   * @summary Sync vector repository
   * @request POST:/repositories/{repositoryId}/vector-repository
   * @secure
   */
  syncVectorRepository = (repositoryId: any, params: RequestParams = {}) =>
    this.request<void, Error>({
      path: `/repositories/${repositoryId}/vector-repository`,
      method: "POST",
      secure: true,
      ...params,
    });
}
