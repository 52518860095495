/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CreateFullAuditDto, FullAudit } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Audits<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Create a new audit for a repository
   *
   * @tags Audit
   * @name CreateFullAudit
   * @summary Create a new audit
   * @request POST:/audits
   * @secure
   */
  createFullAudit = (data: CreateFullAuditDto, params: RequestParams = {}) =>
    this.request<
      FullAudit,
      | {
          error?: string;
        }
      | {
          /** @example "Failed to create audit" */
          message?: string;
        }
    >({
      path: `/audits`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
