import { useRepositoryOverviewSocket } from "../../../api/websocket/useRepositoryOverviewSocket";
import { useRepositoryOverviewStore } from "../../../store/repositoryOverview";
import { AnalysisProgress } from "./AnalysisProgress";
import { GitHubInfo } from "./GitHubInfo";
import { TechStack } from "./TechStack";
import { ProjectSummary } from "./ProjectSummary";
import { OverviewedFiles } from "./OverviewedFiles";
import { ScrollToSummaryButton } from "./ScrollToSummaryButton";
import { useRef, useState, useEffect } from "react";
import { RepositoryOverviewStatus } from "../../../data/data-contracts";
import { AuditHistory } from "./AuditHistory";
import SelectLanguageOverlay from "../../../components/overlays/SelectLanguageOverlay";
import { useGetRepositoryOverview } from "../../../api/repository-overview/repository-overview";
import { ChatSidebar } from "./ChatSidebar";

interface ProjectOverviewProps {
  id: number;
  owner: string;
  repo: string;
  onRequestAudit: () => void;
}

export const ProjectOverview = ({
  id,
  owner,
  repo,
  onRequestAudit,
}: ProjectOverviewProps) => {
  const {
    inProgress,
    stack,
    files,
    progress,
    isFailed,
    summary,
    step,
    status,
  } = useRepositoryOverviewStore();

  const { data: repositoryOverview } = useGetRepositoryOverview(id.toString());

  const summaryRef = useRef<HTMLDivElement>(null);
  const [wasButtonClicked, setWasButtonClicked] = useState(false);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useRepositoryOverviewSocket(id);

  useEffect(() => {
    const handleScroll = () => {
      if (summaryRef.current) {
        const rect = summaryRef.current.getBoundingClientRect();
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isInView && status === RepositoryOverviewStatus.COMPLETED) {
          setWasButtonClicked(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [status]);

  const handleScrollToSummary = () => {
    summaryRef.current?.scrollIntoView({ behavior: "smooth" });
    setWasButtonClicked(true);
  };

  const showScrollButton =
    !wasButtonClicked &&
    step === 5 &&
    status === RepositoryOverviewStatus.COMPLETED &&
    !isFailed;

  const handleOpenChat = () => {
    setIsChatOpen(true);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-6">
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-6">
          <GitHubInfo
            organization={owner}
            repository={repo}
            repositoryOverview={repositoryOverview}
            onOpenChat={handleOpenChat}
          />
          <AnalysisProgress
            inProgress={inProgress && step < 5 && !isFailed}
            isFailed={status === RepositoryOverviewStatus.FAILED}
            step={step}
            progress={progress}
            repoId={id.toString()}
          />
          <AuditHistory
            inProgress={inProgress && step < 5 && !isFailed}
            status={status}
            isFailed={status === RepositoryOverviewStatus.FAILED}
            onRequestAudit={onRequestAudit}
            setIsLanguageModalOpen={setIsLanguageModalOpen}
            repositoryId={id.toString()}
            repositoryName={repo}
            auditStatus={repositoryOverview?.auditStatus}
            lastAuditDate={repositoryOverview?.lastAuditDate}
            audits={repositoryOverview?.audits}
          />
        </div>

        <TechStack inProgress={step < 2 && !isFailed} data={stack || []} />

        <OverviewedFiles
          inProgress={step < 2 && !isFailed}
          files={files || []}
        />

        <div ref={summaryRef}>
          <ProjectSummary
            inProgress={step < 5 && !isFailed}
            summary={summary}
          />
        </div>
      </div>

      {showScrollButton && (
        <ScrollToSummaryButton onClick={handleScrollToSummary} />
      )}

      <SelectLanguageOverlay
        isLanguageModalOpen={isLanguageModalOpen}
        setIsLanguageModalOpen={setIsLanguageModalOpen}
        languages={["JavaScript", "TypeScript", "Python", "Ruby", "PHP"]}
        id={id}
      />

      <ChatSidebar
        isOpen={isChatOpen}
        onClose={() => setIsChatOpen(false)}
        repositoryId={id.toString()}
        repositoryName={repo}
      />
    </div>
  );
};
