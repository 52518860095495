import { Transition, Dialog } from "@headlessui/react";
import { IconX } from "@tabler/icons-react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateAudit } from "../../api/audits";
import { useGetRepositoryLanguage } from "../../api/repositories";
import { toast } from "react-toastify";

interface Props {
  isLanguageModalOpen: boolean;
  setIsLanguageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  languages: string[];
  id: number;
}

const SelectLanguageOverlay = (props: Props) => {
  const { isLanguageModalOpen, setIsLanguageModalOpen, languages, id } = props;

  const { language, isLoading } = useGetRepositoryLanguage(
    id,
    isLanguageModalOpen
  );

  const userId = localStorage.getItem("userId");
  const localStorageData = localStorage.getItem(userId as string);

  const [skipMessage] = useState<boolean>(
    localStorageData
      ? JSON.parse(localStorageData).doNotShowWarningModal
      : false
  );

  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    language
  );

  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const [isManualSelection, setIsManualSelection] = useState(false);

  const handleManualSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsManualSelection(true);
      setSelectedLanguage(languages[0]);
    } else {
      setIsManualSelection(false);
      setSelectedLanguage(language);
    }
  };

  const { mutateAsync: createAudit } = useCreateAudit();

  const handleCreateAudit = async () => {
    if (selectedLanguage) {
      await createAudit({ repositoryId: id, language: selectedLanguage });

      return;
    }

    toast.error("Please select a language");
  };

  const handleCloseDialog = () => {
    setIsLanguageModalOpen(false);
    setIsManualSelection(false);
    setSelectedLanguage(language);
  };

  const dialogBody = (
    <div
      id="styled-scroll"
      className="w-full bg-white shadow-xl rounded-2xl flex flex-col"
    >
      <div
        className="flex flex-col gap-3 px-6 pt-5 pb-3"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="font-semibold text-xl text-gray-900">
          Request Code Audit
        </div>

        <div className="w-full flex flex-col gap-3">
          {language && !isManualSelection && (
            <div className="w-full p-4 rounded-xl bg-gradient-to-r from-gray-50 to-gray-100 border border-gray-200 shadow-sm">
              <div className="flex flex-col gap-2.5">
                <div className="text-sm uppercase tracking-wider text-gray-500 font-medium">
                  Repository Analysis
                </div>
                <div className="flex items-center gap-3">
                  <div className="flex items-center gap-2.5">
                    <div className="w-3 h-3 rounded-full bg-green-500 ring-4 ring-green-50"></div>
                    <span className="text-lg font-semibold text-gray-900">
                      {language}
                    </span>
                  </div>
                  <span className="text-gray-500">
                    detected as main language
                  </span>
                </div>
              </div>
            </div>
          )}

          {language && (
            <div className="w-full p-4 rounded-xl bg-white border border-gray-200 hover:bg-gray-50 transition-colors cursor-pointer shadow-sm">
              <label
                htmlFor="manual-selection"
                className="flex items-center gap-3 cursor-pointer"
              >
                <input
                  type="checkbox"
                  id="manual-selection"
                  checked={isManualSelection}
                  onChange={handleManualSelection}
                  className="w-4 h-4 text-custom-900 rounded border-gray-300 focus:ring-custom-900"
                />
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-900">
                    Manual Language Selection
                  </span>
                  <span className="text-sm text-gray-500">
                    Choose a different programming language
                  </span>
                </div>
              </label>
            </div>
          )}

          {!language && !isManualSelection && (
            <div className="w-full p-4 rounded-xl bg-gradient-to-r from-gray-50 to-gray-100 border border-gray-200 shadow-sm">
              <div className="flex flex-col gap-2">
                <div className="text-sm uppercase tracking-wider text-gray-500 font-medium">
                  Language Selection Required
                </div>
                <div className="text-sm text-gray-600 leading-relaxed">
                  No main language was detected. Please select the programming
                  language for your repository.
                </div>
              </div>
            </div>
          )}

          {(isManualSelection || !language) && (
            <div className="w-full space-y-2">
              <div className="text-sm font-medium text-gray-700">
                Select Language
              </div>
              <select
                className="w-full p-3 text-base border border-gray-200 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-custom-400 focus:border-transparent transition-all hover:border-gray-300"
                onChange={(e) => setSelectedLanguage(e.target.value)}
                value={selectedLanguage || ""}
              >
                {languages.map((lang, index) => (
                  <option key={index} value={lang}>
                    {lang}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {selectedLanguage && !languages.includes(selectedLanguage) && (
          <div className="w-full">
            <div className="flex items-start gap-3 p-4 rounded-xl border border-yellow-100 bg-yellow-50 text-yellow-800 shadow-sm">
              <svg
                className="w-5 h-5 text-yellow-600 flex-shrink-0 mt-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <div className="flex flex-col gap-1">
                <p className="text-sm font-medium">Language Support Notice</p>
                <p className="text-sm font-normal">
                  License check is not supported for this language.
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="w-full">
          <div className="flex items-start gap-3 p-4 rounded-xl border border-blue-100 bg-blue-50 text-blue-800 shadow-sm">
            <svg
              className="w-5 h-5 text-blue-600 flex-shrink-0 mt-0.5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium">Processing Time</p>
              <p className="text-sm font-normal">
                Please note that this process may take some time. You will be
                notified once it's complete.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-100 flex justify-between items-center px-6 py-4 bg-gray-50 rounded-b-2xl">
        <button
          className="px-6 py-2.5 bg-white hover:bg-gray-50 text-gray-700 text-sm font-medium border border-gray-200 rounded-lg transition-colors duration-200"
          onClick={handleCloseDialog}
        >
          Cancel
        </button>
        <button
          className={`px-6 py-2.5 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm ${
            !selectedLanguage
              ? "bg-gray-100 text-gray-400 cursor-not-allowed"
              : "bg-custom-900 hover:bg-custom-800 text-white"
          }`}
          disabled={!selectedLanguage}
          onClick={async () => {
            await handleCreateAudit();
            if (skipMessage) {
              setIsLanguageModalOpen(false);
              navigate("/dashboard");
            } else {
              setIsLanguageModalOpen(false);
            }
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );

  return (
    <Transition.Root show={isLanguageModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-10"
        onClose={handleCloseDialog}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-black/40" />

          <div className="fixed w-5/6 sm:w-[500px] left-0 right-0 mx-auto my-auto top-0 bottom-0 flex items-center">
            <Transition.Child
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <div className="w-full flex relative">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-2 right-2 flex">
                    <button
                      type="button"
                      className="text-custom-400"
                      onClick={handleCloseDialog}
                    >
                      <IconX className="h-6 w-6" />
                    </button>
                  </div>
                </Transition.Child>
                {isLoading ? (
                  <div className="w-full flex justify-center items-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-custom-900"></div>
                  </div>
                ) : (
                  dialogBody
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SelectLanguageOverlay;
