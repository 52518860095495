import React, { Ref } from "react";
import { User, UserStatus } from "../../../data/data-contracts";
import UserItem from "./UserItem";

interface UserTableProps {
  users: User[];
  observeRef: Ref<HTMLDivElement>;
  onUpgradePlan: (userId: number, status: UserStatus) => void;
  onDeleteUser: (userId: number) => void;
}

const UserTable: React.FC<UserTableProps> = ({
  users,
  observeRef,
  onUpgradePlan,
  onDeleteUser,
}) => {
  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden mx-auto border border-gray-200">
      {/* Table Header */}
      <div className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200">
        <div className="grid grid-cols-7 gap-4 px-6 py-5">
          <div className="col-span-2">
            <div className="flex items-center space-x-2">
              <span className="text-xs font-bold text-gray-500 uppercase tracking-wider">
                Name
              </span>
              <div className="h-4 w-px bg-gray-300"></div>
            </div>
          </div>
          <div className="col-span-2">
            <div className="flex items-center space-x-2">
              <span className="text-xs font-bold text-gray-500 uppercase tracking-wider">
                Email
              </span>
              <div className="h-4 w-px bg-gray-300"></div>
            </div>
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <span className="text-xs font-bold text-gray-500 uppercase tracking-wider">
                Status
              </span>
              <div className="h-4 w-px bg-gray-300"></div>
            </div>
          </div>
          <div className="col-span-2">
            <span className="text-xs font-bold text-gray-500 uppercase tracking-wider text-center block">
              Manage User
            </span>
          </div>
        </div>
      </div>

      {/* Table Body */}
      <div className="divide-y divide-gray-100">
        {users && users.length > 0 ? (
          users.map((user, index) => (
            <UserItem
              observeRef={index === users.length - 1 ? observeRef : null}
              key={user.id}
              user={user}
              onUpgradePlan={onUpgradePlan}
              onDeleteUser={onDeleteUser}
            />
          ))
        ) : (
          <div className="flex flex-col items-center justify-center py-16 px-4">
            <div className="text-gray-400 mb-2">
              <svg
                className="w-12 h-12"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p className="text-gray-500 text-sm font-medium">No users found</p>
            <p className="text-gray-400 text-xs mt-1">
              Users will appear here once added to the system
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserTable;
