import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setEmail, setLogged, setLogout } from "../store/userSlice";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { refresh } from "../api/auth";
import { debounce } from "lodash";

const useAuth = (role?: "admin") => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { isLogged, email } = useSelector((store: RootState) => store.user);

  useEffect(() => {
    const token =
      role === "admin"
        ? localStorage.getItem("adminToken")
        : localStorage.getItem("token");

    setIsAuthenticated(!!token);
  }, [role]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setLogged());
    } else {
      //dispatch(setLogout());
    }
  }, [dispatch, isAuthenticated]);

  const refreshDebounce = debounce(() => {
    refresh().then((res) => dispatch(setEmail(res || "")));
  }, 300);

  useEffect(() => {
    if (!email) {
      refreshDebounce();
    }
  }, []);

  return isAuthenticated || isLogged;
};

export default useAuth;
