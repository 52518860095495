import { AxiosError, AxiosResponse } from "axios";
import { instance } from "./index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserType } from "../types/users";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { User, type Error } from "../data/data-contracts";
import { API } from "../data";
import { toast } from "react-toastify";
import { useUserStore } from "../store/userStore";
import { useEffect } from "react";

const controller = new AbortController();

export const putUser = createAsyncThunk(
  "users/updateUser",
  async (data: UserType, { signal }) => {
    signal.addEventListener("abort", () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.put("/users", data);
    return response.data;
  }
);

export const getMe = createAsyncThunk(
  "users/getMe",
  async (_data, { signal }) => {
    signal.addEventListener("abort", () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.get("/users/me");
    return response.data;
  }
);

enum QueryKeys {
  getMe = "users/getMe",
}

export const useGetMe = () => {
  const { setUser } = useUserStore();

  const query = useQuery<User, Error>({
    queryKey: [QueryKeys.getMe],
    queryFn: async () => {
      const response = await API.users.getCurrentUser();
      console.log("response", response);
      return response.data as User;
    },
  });

  useEffect(() => {
    if (query.data) {
      setUser(query.data);
    }
  }, [query.data, setUser]);

  return query;
};

export const useUpgradePlan = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    AxiosResponse<void>,
    AxiosError<{ error: Error }>,
    { userId: number }
  >({
    mutationFn: () => API.users.requestPlanUpgrade(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.getMe] });
      toast.success(
        "Your upgrade request has been submitted and will be reviewed by our team!"
      );
    },
    onError: (error) => {
      const err = error.response?.data.error;
      toast.error(err?.payload?.message || "Failed to upgrade user plan");
    },
  });

  return mutation;
};
