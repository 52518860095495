import React, { memo } from "react";
import { IconWifiOff, IconLoader2 } from "@tabler/icons-react";
import { InputHelperTextProps } from "./ChatTypes";

/**
 * Helper text component for input area
 */
const InputHelperText = memo(
  ({ isConnected, isConnecting }: InputHelperTextProps) => (
    <div className="mt-3 text-sm text-gray-500 text-center">
      {isConnecting ? (
        <span className="flex items-center justify-center gap-2 text-blue-600">
          <IconLoader2 size={16} className="animate-spin" />
          Connecting to chat service...
        </span>
      ) : isConnected ? (
        <span>
          Press{" "}
          <kbd className="bg-gray-100 px-2 py-1 rounded border border-gray-300 mx-1 text-xs">
            Enter
          </kbd>{" "}
          to send,{" "}
          <kbd className="bg-gray-100 px-2 py-1 rounded border border-gray-300 mx-1 text-xs">
            Shift+Enter
          </kbd>{" "}
          for new line
        </span>
      ) : (
        <span className="flex items-center justify-center gap-2 text-yellow-600">
          <IconWifiOff size={16} />
          Chat service is currently offline
        </span>
      )}
    </div>
  )
);

InputHelperText.displayName = "InputHelperText";

export default InputHelperText;
