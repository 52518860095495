import { TimestampType } from "./ChatTypes";

/**
 * Formats a timestamp into a human-readable time format
 * Accepts different timestamp formats and returns a consistent time string
 */
export const formatMessageTime = (timestamp: TimestampType): string => {
  if (!timestamp) return "";

  let date: Date;

  if (timestamp instanceof Date) {
    date = timestamp;
  } else if (typeof timestamp === "number" || typeof timestamp === "string") {
    date = new Date(timestamp);
  } else {
    return "";
  }

  // Check if valid date
  if (isNaN(date.getTime())) return "";

  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};
