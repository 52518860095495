import { AxiosInstance } from "axios";

import { ApiConfig, HttpClient } from "./http-client";
import { Repositories } from "./Repositories";
import { Admins } from "./Admins";
import { Users } from "./Users";
import { Audits } from "./Audits";
import { Projects } from "./Projects";
import { RemoteRepos } from "./RemoteRepos";

type Constructor<T> = new (...args: any[]) => T;

export class ApiController {
  public readonly repositoryOverview: Repositories<any> =
    this.registerProtectedClient(Repositories);
  public readonly admins: Admins<any> = this.registerProtectedClient(Admins);
  public readonly users: Users<any> = this.registerProtectedClient(Users);
  public readonly repositories: Repositories<any> =
    this.registerProtectedClient(Repositories);
  public readonly audits: Audits<any> = this.registerProtectedClient(Audits);
  private refreshTokenPromise: Promise<void> | null = null;
  public readonly projects: Projects<any> =
    this.registerProtectedClient(Projects);
  public readonly remoteRepos: RemoteRepos<any> =
    this.registerProtectedClient(RemoteRepos);

  private registerProtectedClient<ApiClient extends HttpClient>(
    Client: Constructor<ApiClient>
  ): ApiClient {
    const baseAxiosConfig = this.createBaseAxiosConfig();
    const client = new Client(baseAxiosConfig);

    this.addAuthInterceptor(client.instance);
    //this.addRefreshTokenInterceptor(client.instance);

    return client;
  }

  private addAuthInterceptor(instance: AxiosInstance): void {
    instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  // TODO: add logic refresh token
  // private registerAuthClient() {
  //   const client = new Auth(this.createBaseAxiosConfig());

  //   client.instance.interceptors.response.use((response: any) => {
  //     const url = response.config.url;

  //     // Define the routes for which the tokens should be saved
  //     const tokenSavingRoutes = ['/login'];

  //     if (url && tokenSavingRoutes.includes(url)) {
  //       const { accessToken, refreshToken } = response.data as any;

  //       localStorage.setItem('token', accessToken);
  //       localStorage.setItem('refresh-token', refreshToken);
  //     }

  //     return response;
  //   });

  //   return client;
  // }

  // private addRefreshTokenInterceptor(instance: AxiosInstance): void {
  //   instance.interceptors.response.use(
  //     (response) => response,
  //     async (error) => {
  //       const { data, config = {} } = error.response;
  //       // Prevent infinite retry loops
  //       if (config._retry) return Promise.reject(error);

  //       const refreshToken = Cookies.get('refresh-token');

  //       if (refreshToken && data && typeof data === 'object' && data.error.payload.message === 'Expired token') {
  //         config._retry = true; // Mark this request as retried

  //         const processRefreshToken = async () => {
  //           try {
  //             await this.auth.refreshSession({ refreshToken });
  //           } catch (error) {
  //             Cookies.remove('refresh-token');
  //             throw error;
  //           }
  //         };

  //         if (!this.refreshTokenPromise) this.refreshTokenPromise = processRefreshToken();
  //         await this.refreshTokenPromise;

  //         // After successful token refresh, retry the original request
  //         return instance(config);
  //       }

  //       return Promise.reject(error);
  //     }
  //   );
  // }

  private createBaseAxiosConfig(): ApiConfig {
    const baseURL = "https://code-audit-web-backend-940d30d62852.herokuapp.com";

    if (!baseURL) {
      throw new Error("Base URL is not defined in environment variables");
    }

    return {
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  }
}

export const API = new ApiController();
