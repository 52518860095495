import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { API } from "../data";
import {
  PaginatedUserList,
  SignInAdminRequest,
  User,
  UserStatus,
} from "../data/data-contracts";
import { useNavigate } from "react-router-dom";
import { AxiosResponse, AxiosError } from "axios";
import { SignInAdminResponse, type Error } from "../data/data-contracts";
import { toast } from "react-toastify";

enum QueryKeys {
  getAllUsers = "getAllUsers",
  upgradeUserPlan = "upgradeUserPlan",
}

export const useAdminLogin = () => {
  const navigate = useNavigate();

  const mutation = useMutation<
    AxiosResponse<SignInAdminResponse>,
    AxiosError<{ error: Error }>,
    SignInAdminRequest
  >({
    mutationFn: (data: SignInAdminRequest) => API.admins.signInAdmin(data),
    onSuccess: (data) => {
      localStorage.setItem("token", data.data.authToken);
      localStorage.setItem("isAdmin", "true");
      navigate("/admin/dashboard");

      console.log("Successfully logged in!");
    },
    onError: (error) => {
      const err = error.response?.data.error;
      toast.error(
        err?.payload?.message || "Failed to login. Please try again."
      );
    },
  });

  return mutation;
};

type GetAllUsersQueryParams = NonNullable<
  Parameters<typeof API.admins.getUsers>[0]
>;
export type GetAllUsersPageParams = { query: GetAllUsersQueryParams };

export const useGetAllUsers = () => {
  const getQueryParams = (page: number) => ({
    page,
    limit: 10,
  });

  const {
    data: response,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery<
    PaginatedUserList,
    Error,
    InfiniteData<PaginatedUserList>,
    QueryKey,
    GetAllUsersPageParams
  >({
    queryKey: [QueryKeys.getAllUsers],
    queryFn: async ({ pageParam }) => {
      const { query } = pageParam;

      const response = await API.admins.getUsers(query);
      return response.data;
    },
    initialPageParam: {
      query: getQueryParams(1),
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.hasNext) {
        const query = getQueryParams(lastPage.meta.currentPage + 1);

        return { query };
      }
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });

  const users = response?.pages.reduce((acc, page) => {
    return acc.concat(page.data);
  }, [] as User[]);

  return { users, fetchNextPage, hasNextPage, isLoading };
};

export const useUpgradeUserPlan = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    AxiosResponse<void>,
    AxiosError<{ error: Error }>,
    { userId: number; status: UserStatus }
  >({
    mutationFn: (data: { userId: number; status: UserStatus }) =>
      API.admins.changeUserStatus(data.userId, {
        status: data.status,
      }),
    onSuccess: () => {
      toast.success("User plan upgraded successfully");
      queryClient.invalidateQueries({ queryKey: [QueryKeys.getAllUsers] });
    },
    onError: (error) => {
      const err = error.response?.data.error;
      toast.error(err?.payload?.message || "Failed to upgrade user plan");
    },
  });

  return mutation;
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    AxiosResponse<void>,
    AxiosError<{ error: Error }>,
    number
  >({
    mutationFn: (userId: number) => API.admins.deleteUser(userId),
    onSuccess: () => {
      toast.success("User deleted successfully");
      queryClient.invalidateQueries({ queryKey: [QueryKeys.getAllUsers] });
    },
    onError: (error) => {
      const err = error.response?.data.error;
      toast.error(err?.payload?.message || "Failed to delete user");
    },
  });

  return mutation;
};
