import { IconFiles } from "@tabler/icons-react";
import { RepositoryFile } from "../../../data/data-contracts";
import FilesTable from "../../../components/ui/FilesTable";
import { Label } from "./Label";
import { useMemo } from "react";

interface OverviewedFilesProps {
  files: RepositoryFile[];
  inProgress: boolean;
}

export const OverviewedFiles = ({
  files,
  inProgress,
}: OverviewedFilesProps) => {
  const renderContent = useMemo(() => {
    switch (true) {
      case files?.length === 0 && !inProgress:
        return (
          <div className="p-4 rounded-lg bg-gray-50 border border-gray-200">
            <p className="text-gray-600 text-sm">
              No important files have been detected for this repository.
            </p>
          </div>
        );
      default:
        return null;
    }
  }, [inProgress, files]);

  const shouldShowTable = inProgress || files?.length > 0;

  return (
    <div className="flex flex-col gap-6">
      <Label icon={IconFiles}>
        {inProgress ? (
          <>
            We found
            <span className="inline-block w-6 h-4 bg-gray-200 animate-pulse rounded align-middle mx-1" />
            important files
          </>
        ) : (
          `We found ${files?.length || 0} important files`
        )}
      </Label>
      {renderContent}
      {shouldShowTable && (
        <FilesTable files={files || []} isLoading={inProgress} />
      )}
    </div>
  );
};
