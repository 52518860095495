import React, { memo } from "react";
import { IconMessageCircle, IconLoader2 } from "@tabler/icons-react";
import { WelcomeViewProps } from "./ChatTypes";
import OfflineMessage from "./OfflineMessage";

/**
 * Welcome message shown when no conversation history exists
 */
const WelcomeView = memo(
  ({
    repositoryName,
    isConnected,
    isLoading,
    isConnecting,
    presetQueries,
    onPresetQueryClick,
  }: WelcomeViewProps) => (
    <div className="text-gray-500 text-center py-6 h-full flex flex-col items-center justify-center">
      <div className="bg-white p-6 rounded-2xl shadow-sm max-w-md mx-auto w-full border border-gray-200 pulse-animation">
        <IconMessageCircle
          className="h-12 w-12 mx-auto mb-4 text-custom-900"
          stroke={1.5}
        />
        <h3 className="text-lg font-medium text-custom-900 mb-2">
          Welcome to {repositoryName} Chat
        </h3>
        <p className="text-gray-600 mb-6">
          Ask questions about the codebase to get quick, accurate answers.
        </p>

        {/* Preset query buttons */}
        <div className="flex flex-col gap-3 w-full">
          {presetQueries.map((query, index) => (
            <button
              key={index}
              onClick={() => onPresetQueryClick(query.text)}
              disabled={isLoading || !isConnected || isConnecting}
              className={`py-3 px-4 bg-white hover:bg-gray-50 active:bg-gray-100 text-custom-900 rounded-xl border border-gray-200 text-left transition-all flex items-center gap-2 shadow-sm hover:shadow ${
                !isConnected || isConnecting
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              {query.icon}
              <span className="font-medium">
                {query.text.replace(query.emoji + " ", "")}
              </span>
            </button>
          ))}
        </div>

        {/* Show connecting message */}
        {isConnecting && (
          <div className="mt-8 bg-blue-50 p-5 rounded-xl border border-blue-200 shadow-md flex items-center justify-center gap-2">
            <IconLoader2 size={20} className="animate-spin text-blue-600" />
            <span className="text-blue-700">Connecting to chat service...</span>
          </div>
        )}

        {/* Show offline message if offline */}
        {!isConnected && !isConnecting && (
          <div className="mt-8 bg-yellow-50 p-5 rounded-xl border border-yellow-200 shadow-md">
            <OfflineMessage />
          </div>
        )}
      </div>
    </div>
  )
);

WelcomeView.displayName = "WelcomeView";

export default WelcomeView;
