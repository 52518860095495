import axios, { AxiosError, AxiosResponse } from "axios";
import { instance } from "./index";
import {
  TsignInSuccess,
  IsignIn,
  TsignUpSuccess,
  IsignUp,
  TrefreshSuccess,
} from "../types/auth";
import saveUserTokens from "../utils/saveUserToken";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const signin = (data: IsignIn): Promise<TsignInSuccess | void> => {
  const { email, password } = data;

  return instance
    .post("/auth/sign_in", {
      email,
      password,
    })
    .then(function (response: AxiosResponse<TsignInSuccess>) {
      saveUserTokens({
        token: response.data.authToken,
        refreshToken: response.data.refreshToken,
        email: response.data.email,
      });
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, "error");
        const message = error?.response?.data;
        throw new Error(message);
      }
    });
};

export const signup = (data: IsignUp): Promise<TsignUpSuccess | void> => {
  const { email, password } = data;

  return instance
    .post("/auth/sign_up", {
      email,
      password,
    })
    .then(function (response: AxiosResponse<TsignUpSuccess>) {
      saveUserTokens({
        token: response.data.authToken,
        refreshToken: response.data.refreshToken,
        email: response.data.email,
      });
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, "error");
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const refresh = (): Promise<string | void> => {
  return Promise.resolve();
  // const refreshToken = localStorage.getItem("refreshToken");
  // if (!refreshToken) return Promise.resolve();
  // return instance
  //   .post("/auth/refresh", {
  //     refreshToken: refreshToken,
  //   })
  //   .then(function (response: AxiosResponse<TrefreshSuccess>) {
  //     saveUserTokens({
  //       token: response.data.authToken,
  //       refreshToken: response.data.refreshToken,
  //       email: response.data.email,
  //     });
  //     return response.data.email;
  //   })
  //   .catch(function (error: AxiosError) {
  //     console.log(error);
  //   });
};

export const adminSignin = createAsyncThunk(
  "auth/adminSignin",
  async (data: { email: string; password: string }) => {
    try {
      const response = await instance.post("/admin/signin", data);
      if (response.data) {
        localStorage.setItem("adminToken", response.data.token);
        return response.data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  }
);
