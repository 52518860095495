/**
 * Global style definitions for chat component
 */
export const scrollbarHideStyles = `
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Chat animations */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes pulseIn {
    0% { opacity: 0; transform: scale(0.97); }
    100% { opacity: 1; transform: scale(1); }
  }
  
  .message-animation {
    animation: fadeIn 0.3s ease-out forwards;
  }
  
  .pulse-animation {
    animation: pulseIn 0.2s ease-out forwards;
  }
  
  /* Message bubble tail styling */
  .message-user {
    position: relative;
  }
  
  .message-user:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -8px;
    width: 16px;
    height: 16px;
    background: #10b981;
    border-bottom-left-radius: 16px;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }
  
  .message-bot {
    position: relative;
  }
  
  .message-bot:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -8px;
    width: 16px;
    height: 16px;
    background: #f3f4f6;
    border-bottom-right-radius: 16px;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
  }
`;
