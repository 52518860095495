import React from "react";
import { Link } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";

const ErrorPage: React.FC = () => {
  return (
    <MainLayout>
      <div className="min-h-[70vh] flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <div className="text-6xl font-bold text-red-600 mb-4">
            <span role="img" aria-label="error">
              ⚠️
            </span>
          </div>
          <h1 className="text-3xl font-bold text-gray-900">
            Something went wrong
          </h1>
          <p className="mt-4 text-xl text-gray-600">
            We're experiencing some technical difficulties
          </p>
          <p className="mt-2 text-base text-gray-500">
            Please try again later or contact support if the problem persists.
          </p>
          <div className="mt-6 space-x-4">
            <Link
              to="/"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Go back home
            </Link>
            <button
              onClick={() => window.location.reload()}
              className="inline-flex items-center px-4 py-2 border border-black text-sm font-medium rounded-md shadow-sm text-black bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Try again
            </button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ErrorPage;
