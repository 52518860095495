import React, { useEffect } from "react";
import { MainLayout } from "../../../components/layout/MainLayout";
import {
  useGetAllUsers,
  useUpgradeUserPlan,
  useDeleteUser,
} from "../../../api/admins";
import { useInView } from "react-intersection-observer";
import UserTable from "../components/UserTable";
import { UserStatus } from "../../../data/data-contracts";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../components/fields/Logo";
import { IconLogout } from "@tabler/icons-react";

const AdminDashboardPage = () => {
  const navigate = useNavigate();
  const { users, hasNextPage, fetchNextPage } = useGetAllUsers();
  const { mutateAsync: upgradeUserPlan } = useUpgradeUserPlan();
  const { mutateAsync: deleteUser } = useDeleteUser();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) void fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  console.log("### users", users);

  const handleUpgradePlan = (userId: number, status: UserStatus) => {
    void upgradeUserPlan({ userId, status });
  };

  const handleDeleteUser = (userId: number) => {
    console.log("### userId", userId);
    void deleteUser(userId);
  };

  const handleLogout = () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("isAdmin");
      console.log("Logging out, navigating to /admin/login");
      navigate("/admin/login", { replace: true });
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="w-full px-10 py-2">
        <div className="flex justify-between items-center w-full mb-10">
          <div className="flex items-center justify-start gap-8 h-16 w-full">
            <Link to="/admin/dashboard">
              <Logo />
            </Link>
          </div>
          <button
            onClick={handleLogout}
            className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 rounded-md border border-gray-300 shadow-sm transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            <IconLogout size={20} stroke={1.5} />
            Logout
          </button>
        </div>
      </div>
      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          {users && (
            <UserTable
              observeRef={ref}
              users={users}
              onUpgradePlan={handleUpgradePlan}
              onDeleteUser={handleDeleteUser}
            />
          )}
        </div>
      </main>
    </div>
  );
};

export default AdminDashboardPage;
