import React, { Ref } from "react";
import { User, UserStatus } from "../../../data/data-contracts";

interface UserItemProps {
  user: User;
  observeRef: Ref<HTMLDivElement> | null;
  onUpgradePlan: (userId: number, status: UserStatus) => void;
  onDeleteUser: (userId: number) => void;
}

const UserItem: React.FC<UserItemProps> = ({
  user,
  observeRef,
  onUpgradePlan,
  onDeleteUser,
}) => {
  const fullName =
    `${user.firstName || ""} ${user.lastName || ""}`.trim() || "N/A";

  const getStatusDisplay = (status: UserStatus) => {
    switch (status) {
      case UserStatus.Premium:
        return "Premium 💰";
      case UserStatus.PremiumRequested:
        return "Pending ⏳";
      case UserStatus.Freemium:
      default:
        return "Freemium 🔼";
    }
  };

  const getActionButton = () => {
    switch (user.status) {
      case UserStatus.Premium:
        return (
          <button
            onClick={() => onUpgradePlan(user.id, UserStatus.Freemium)}
            className="inline-flex items-center px-3 py-1.5 rounded-md text-sm font-medium bg-orange-50 text-orange-600 hover:bg-orange-100 hover:shadow-sm transition-all duration-200 active:bg-orange-200"
          >
            🔼 Downgrade to Freemium
          </button>
        );
      case UserStatus.PremiumRequested:
        return (
          <button
            onClick={() => onUpgradePlan(user.id, UserStatus.Premium)}
            className="inline-flex items-center px-3 py-1.5 rounded-md text-sm font-medium bg-green-50 text-green-600 hover:bg-green-100 hover:shadow-sm transition-all duration-200 active:bg-green-200"
          >
            ✅ Approve Premium
          </button>
        );
      case UserStatus.Freemium:
      default:
        return (
          <button
            onClick={() => onUpgradePlan(user.id, UserStatus.Premium)}
            className="inline-flex items-center px-3 py-1.5 rounded-md text-sm font-medium bg-blue-50 text-blue-600 hover:bg-blue-100 hover:shadow-sm transition-all duration-200 active:bg-blue-200"
          >
            🚀 Upgrade to Premium
          </button>
        );
    }
  };

  return (
    <div
      ref={observeRef}
      className="grid grid-cols-7 gap-4 px-6 py-4 hover:bg-gray-50 transition-all duration-200 group"
    >
      <div className="col-span-2 flex items-center">
        <div className="flex items-center space-x-3">
          <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
            <span className="text-sm font-medium text-gray-600">
              {fullName.charAt(0).toUpperCase()}
            </span>
          </div>
          <span className="font-medium text-gray-900 group-hover:text-blue-600 transition-colors duration-200">
            {fullName}
          </span>
        </div>
      </div>
      <div className="col-span-2 flex items-center">
        <span className="text-gray-600">{user.email}</span>
      </div>
      <div className="flex items-center">
        <span
          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
            user.status === UserStatus.Premium
              ? "bg-green-100 text-green-800 ring-1 ring-green-600/20"
              : user.status === UserStatus.PremiumRequested
                ? "bg-yellow-100 text-yellow-800 ring-1 ring-yellow-600/20"
                : "bg-gray-100 text-gray-800 ring-1 ring-gray-600/20"
          }`}
        >
          {getStatusDisplay(user.status)}
        </span>
      </div>
      <div className="col-span-2 flex items-center justify-center space-x-2">
        {getActionButton()}
        <button
          onClick={() => onDeleteUser(user.id)}
          className="inline-flex items-center px-3 py-1.5 rounded-md text-sm font-medium bg-red-50 text-red-600 hover:bg-red-100 hover:shadow-sm transition-all duration-200 active:bg-red-200"
        >
          🗑️ Delete
        </button>
      </div>
    </div>
  );
};

export default UserItem;
