import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { IconX, IconMessageCircle } from "@tabler/icons-react";
import { ChatMessages } from "../../../components/chat";

interface ChatSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  repositoryId: string;
  repositoryName: string;
}

export const ChatSidebar: React.FC<ChatSidebarProps> = ({
  isOpen,
  onClose,
  repositoryId,
  repositoryName,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-10"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-black/40 backdrop-blur-sm" />

          <div className="fixed inset-y-0 right-0 w-full flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="w-1/2 h-full">
                <div className="flex flex-col h-full bg-white shadow-xl rounded-l-lg overflow-hidden">
                  <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200">
                    <Dialog.Title className="text-lg font-semibold text-gray-800 flex items-center">
                      <IconMessageCircle
                        className="h-5 w-5 mr-2 text-custom-900"
                        stroke={1.5}
                      />
                      Ask about {repositoryName}
                    </Dialog.Title>
                    <button
                      type="button"
                      className="text-custom-400 hover:text-custom-900 rounded-md focus:outline-none focus:ring-2 focus:ring-custom-900"
                      onClick={onClose}
                    >
                      <IconX className="h-6 w-6" />
                    </button>
                  </div>

                  <ChatMessages
                    repositoryId={repositoryId}
                    repositoryName={repositoryName}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
