import { TablerIconsProps } from "@tabler/icons-react";
import { ReactNode } from "react";

interface LabelProps {
  icon: (props: TablerIconsProps) => JSX.Element;
  children: ReactNode;
}

export const Label = ({ icon: Icon, children }: LabelProps) => {
  return (
    <div className="flex items-center gap-3">
      <div className="bg-blue-50 p-2 rounded-lg">
        <Icon className="w-6 h-6 text-blue-600" />
      </div>
      <h3 className="text-base font-semibold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
        {children}
      </h3>
    </div>
  );
};
