import useWebSocket from "react-use-websocket";
import { useRepositoryOverviewStore } from "../../store/repositoryOverview";
import { useEffect, useRef } from "react";
import {
  RepositoryOverviewSocketEvent,
  RepositoryOverviewSocketEventMessage,
  RepositoryOverviewStatus,
} from "../../data/data-contracts";

/**
 * @description Connect to the repository overview socket
 * @param repoId - The id of the repository
 * @param disabled - Whether the socket should be disabled
 * @param status - The status of the repository overview
 * @returns The socket instance
 */
export const useRepositoryOverviewSocket = (
  repoId: number,
  disabled: boolean = true,
  status?: RepositoryOverviewStatus
) => {
  const shouldDisable =
    disabled ||
    status === RepositoryOverviewStatus.COMPLETED ||
    status === RepositoryOverviewStatus.FAILED;

  const ws = {
    URL: `wss://code-audit-web-backend-940d30d62852.herokuapp.com/ws/repositories/${repoId}`,

    connect: (repoId: number, authToken: string | null) => {
      return {
        type: RepositoryOverviewSocketEvent.CONNECT,
        data: { repoId, authToken },
      };
    },

    reconnect: (data: { connectionId: string; authToken: string }) => {
      return {
        type: RepositoryOverviewSocketEvent.CONNECT,
        data,
      };
    },

    disconnect: () => {
      return { type: RepositoryOverviewSocketEvent.DISCONNECT };
    },
  };

  const wsRef = useRef(ws);

  useEffect(() => {
    if (shouldDisable) {
      wsRef.current.disconnect();
    }
  }, [shouldDisable]);

  const { setInProgress, setIsFailed, setValues } =
    useRepositoryOverviewStore();

  const { lastJsonMessage } = useWebSocket(
    shouldDisable ? null : wsRef.current.URL,
    {
      share: false,
      shouldReconnect: () => !shouldDisable,
    }
  );

  const message = lastJsonMessage as RepositoryOverviewSocketEventMessage;

  useEffect(() => {
    if (message) {
      switch (message.type) {
        case RepositoryOverviewSocketEvent.CONNECT:
          // skip updating state since first we send Api request
          console.log("### WS Connected");
          break;
        case RepositoryOverviewSocketEvent.PENDING:
          console.log("### Pending");
          break;
        case RepositoryOverviewSocketEvent.GET_STACK_AND_FILES:
          console.log("### WS Get stack and files", message.content);
          setValues(message.content);
          break;
        case RepositoryOverviewSocketEvent.GET_FILES_OVERVIEW:
          console.log("### WS Get files overview", message.content);
          setValues(message.content);
          break;
        case RepositoryOverviewSocketEvent.GET_SUMMARY:
          console.log("### WS Get summary", message.content);
          setValues(message.content);
          break;
        case RepositoryOverviewSocketEvent.OVERVIEW_COMPLETED:
          console.log("### WS Overview completed", message.content);
          setInProgress(false);
          setValues(message.content);

          // Disconnect the WebSocket connection
          wsRef.current.disconnect();
          break;
        case RepositoryOverviewSocketEvent.OVERVIEW_FAILED:
          setInProgress(false);
          setIsFailed(true);
          setValues(message.content);
          break;
      }
    }
  }, [lastJsonMessage, message, setInProgress, setIsFailed, setValues]);

  return wsRef.current;
};
