import { useNavigate, useParams } from "react-router-dom";
import { ProjectOverview } from "./components/ProjectOverview";
import { useRepositoryOverviewStore } from "../../store/repositoryOverview";
import { useEffect, useRef } from "react";
import TopBar from "../../components/TopBar";
import { useGetRepositoryOverview } from "../../api/repository-overview/repository-overview";
import { IconLock, IconSparkles } from "@tabler/icons-react";
import { useUpgradePlan } from "../../api/users";
import { useUserStore } from "../../store/userStore";
import ReportEmptyState from "./components/ReportEmptyState";

export const RepositoryOverviewPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { mutateAsync: upgradePlan } = useUpgradePlan();
  const upgradeButtonRef = useRef<HTMLButtonElement>(null);

  const { isLoading, isError, data, error } = useGetRepositoryOverview(id);
  const { user } = useUserStore();

  const { reset } = useRepositoryOverviewStore();

  const [owner, repo] = data?.repoName?.split("/") || [];

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  useEffect(() => {
    if (!id) {
      navigate("/dashboard");
      return;
    }

    if (error instanceof Error) {
      if (error.message === "NOT_FOUND") {
        navigate("/not-found");
        return;
      }
      if (error.message === "SOMETHING_WENT_WRONG") {
        navigate("/something-went-wrong");
        return;
      }
    }

    if (isError) {
      navigate("/something-went-wrong");
    }
  }, [id, isError, error, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleUpgradePlan = async () => {
    await upgradePlan({ userId: Number(id) });
  };

  const scrollToUpgradeButton = () => {
    upgradeButtonRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <>
      <div className="w-full px-10 py-2">
        <TopBar />
      </div>
      <div className="w-full p-4">
        <h2 className="flex justify-center items-center h-full text-4xl font-bold">
          Project Overview
        </h2>
        <div className="grid grid-cols-6 grid-rows-1 gap-4 pt-4">
          <div className="col-span-6">
            <ProjectOverview
              id={Number(id)}
              owner={owner}
              repo={repo}
              onRequestAudit={scrollToUpgradeButton}
            />
            <div className="flex justify-center mt-8 my-3">
              {user?.status === "Premium_requested" ? (
                <button
                  disabled
                  className="px-8 py-3.5 bg-gray-400 text-white rounded-lg font-semibold flex items-center gap-3 shadow-md cursor-not-allowed transition-all duration-200 opacity-90"
                >
                  <IconSparkles
                    size={24}
                    className="animate-pulse opacity-75"
                  />
                  <span>Upgrade Request Pending ...</span>
                </button>
              ) : user?.status === "Freemium" ? (
                <button
                  ref={upgradeButtonRef}
                  onClick={handleUpgradePlan}
                  className="px-8 py-3.5 bg-gradient-to-r from-emerald-500 to-emerald-600 text-white rounded-lg font-semibold hover:from-emerald-600 hover:to-emerald-700 transition-all duration-200 flex items-center gap-3 shadow-md hover:shadow-lg"
                >
                  <IconLock size={24} className="animate-bounce opacity-90" />
                  <span>Upgrade to See Full Report</span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <ReportEmptyState user={user} />
      </div>
    </>
  );
};
