import Markdown from "react-markdown";
import { IconAlertCircle, IconInfoCircle } from "@tabler/icons-react";
import { ErrorObject, ReportType, TechnologiesUsed } from "../../types/general";
import ReportTabDownloadReport from "./ReportTabDownloadReport";

interface Props {
  report: ReportType;
  updated: Date;
}

export default function ReportTabTechnologies(props: Props) {
  const { report, updated } = props;

  return (
    <>
      <ReportTabDownloadReport updated={updated} />
      <table className="min-w-full divide-y divide-custom-50">
        <thead className="">
          <tr>
            <th className="px-6 py-3 text-left text-base leading-7 font-medium text-custom-400 tracking-wider">
              Technology
            </th>
            <th className="px-6 py-3 text-left text-base leading-7 font-medium text-custom-400 tracking-wider">
              Description
            </th>
          </tr>
        </thead>
        <tbody className="bg-white border-custom-50 border divide-y divide-custom-50">
          {!report.technologiesUsed?.hasOwnProperty("error") ? (
            (report.technologiesUsed as TechnologiesUsed).technologies.map(
              (technology) => (
                <tr
                  key={technology.technologyName}
                  style={{ borderBottom: "1px solid #E8ECEF" }}
                >
                  <td className="px-6 py-4 font-medium text-custom-900 text-base leading-7">
                    {technology.technologyName} ({technology.type})
                  </td>
                  <td className="px-6 py-4 font-normal text-custom-900 text-base leading-7">
                    <Markdown>{technology.explanation}</Markdown>
                  </td>
                </tr>
              )
            )
          ) : (
            <tr>
              <td colSpan={2} className="px-6 py-4">
                {(report.technologiesUsed as ErrorObject).error ===
                "Language is not supported for checking used technologies" ? (
                  <div className="flex items-start gap-3 p-4 bg-blue-50 rounded-lg border border-blue-100">
                    <IconInfoCircle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
                    <div>
                      <div className="font-medium text-blue-700 mb-1">
                        Language Not Supported
                      </div>
                      <div className="text-sm text-blue-600">
                        Technology detection is not supported for this
                        programming language.
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-start gap-3 p-4 bg-red-50 rounded-lg border border-red-100">
                    <IconAlertCircle className="w-5 h-5 text-red-500 flex-shrink-0 mt-0.5" />
                    <div>
                      <div className="font-medium text-red-700 mb-1">
                        Error Occurred
                      </div>
                      <div className="text-sm text-red-600">
                        {(report.technologiesUsed as ErrorObject).error ||
                          "An error occurred while loading technologies. Please try again later."}
                      </div>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
