import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  IconArrowUp,
  IconCode,
  IconBrandGithub,
  IconFileCode,
  IconMoodSmile,
} from "@tabler/icons-react";
import { useChatSocket } from "../../api/websocket/useChatSocket";
import { useChatStore } from "../../store/chatStore";
import { ChatMessagesProps } from "./ChatTypes";
import { scrollbarHideStyles } from "./ChatStyles";
import ConnectionStatus from "./ConnectionStatus";
import OfflineMessage from "./OfflineMessage";
import ThinkingIndicator from "./ThinkingIndicator";
import WelcomeView from "./WelcomeView";
import MessageBubble from "./MessageBubble";
import InputHelperText from "./InputHelperText";
import EmojiPicker from "./EmojiPicker";

/**
 * Main chat component that provides the chat interface
 */
export const ChatMessages: React.FC<ChatMessagesProps> = ({
  repositoryId,
  repositoryName,
}) => {
  // State
  const [question, setQuestion] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [, setOfflineMessageShown] = useState(false);

  // Refs
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // External state from hooks
  const { sendMessage, isConnecting } = useChatSocket(repositoryId);
  const { messages, isLoading, isConnected } = useChatStore();

  // Preset queries data
  const presetQueries = [
    {
      text: "📖 Tell me please about this project",
      emoji: "📖",
      icon: <IconFileCode size={20} className="mr-2" />,
    },
    {
      text: "🛠️ What technologies used in this project",
      emoji: "🛠️",
      icon: <IconCode size={20} className="mr-2" />,
    },
  ];

  // ============================================================================
  // Event Handlers
  // ============================================================================

  /**
   * Handle preset query clicks and send the message if conditions are met
   */
  const handlePresetQueryClick = useCallback(
    (queryText: string) => {
      if (isLoading || !isConnected) return;
      sendMessage(queryText);
    },
    [isLoading, isConnected, sendMessage]
  );

  /**
   * Handles textarea input changes and auto-resizes the textarea
   */
  const handleTextareaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const textarea = e.target;
      setQuestion(textarea.value);

      // Auto-resize logic
      textarea.style.height = "auto";
      const lineHeight = 24; // pixels per line
      const maxHeight = lineHeight * 4; // 4 rows maximum
      const newHeight = Math.min(textarea.scrollHeight, maxHeight);
      textarea.style.height = `${newHeight}px`;
    },
    []
  );

  /**
   * Submit handler for the chat form
   */
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!question.trim() || !isConnected) return;

      // Send message
      sendMessage(question);
      setQuestion("");

      // Reset textarea height
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }

      // Focus back on textarea after sending
      setTimeout(() => {
        textareaRef.current?.focus();
      }, 100);
    },
    [question, isConnected, sendMessage]
  );

  /**
   * Keyboard event handler for submitting with Enter
   */
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e as unknown as React.FormEvent);
      }
    },
    [handleSubmit]
  );

  /**
   * Toggle emoji picker visibility
   */
  const handleEmojiClick = useCallback(() => {
    setShowEmojiPicker((prev) => !prev);
  }, []);

  /**
   * Handle emoji selection and add to input
   */
  const handleEmojiSelect = useCallback((emoji: string) => {
    setQuestion((prev) => prev + emoji);
    textareaRef.current?.focus();
  }, []);

  // ============================================================================
  // Effects
  // ============================================================================

  /**
   * Focus textarea on component mount
   */
  useEffect(() => {
    textareaRef.current?.focus();

    const focusTimeout = setTimeout(() => {
      textareaRef.current?.focus();
    }, 100);

    return () => clearTimeout(focusTimeout);
  }, []);

  /**
   * Handle focus when window/document visibility changes
   */
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setTimeout(() => textareaRef.current?.focus(), 100);
      }
    };

    const handleWindowFocus = () => {
      setTimeout(() => textareaRef.current?.focus(), 100);
    };

    // Track click events inside the component to ensure focus remains
    const handleChatClick = () => {
      // Only focus if we're not clicking on a button or another interactive element
      if (
        document.activeElement?.tagName !== "BUTTON" &&
        document.activeElement?.tagName !== "A" &&
        document.activeElement !== textareaRef.current
      ) {
        textareaRef.current?.focus();
      }
    };

    // Add event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleWindowFocus);
    chatContainerRef.current?.addEventListener("click", handleChatClick);

    return () => {
      // Clean up event listeners
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleWindowFocus);
      chatContainerRef.current?.removeEventListener("click", handleChatClick);
    };
  }, []);

  /**
   * Scroll to bottom whenever messages change
   */
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  /**
   * Add custom styles to document head
   */
  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = scrollbarHideStyles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  /**
   * Update offline notice state when connection status changes
   */
  useEffect(() => {
    setOfflineMessageShown(!isConnected);
  }, [isConnected]);

  // ============================================================================
  // Render
  // ============================================================================

  return (
    <div className="flex flex-col h-full bg-gray-50" ref={chatContainerRef}>
      {/* Chat header */}
      <div className="bg-white border-b border-gray-200 py-3 px-4 flex items-center justify-between shadow-sm">
        <div className="flex items-center">
          <IconBrandGithub size={22} className="text-custom-900 mr-2" />
          <h2 className="font-medium text-custom-900">
            {repositoryName} Assistant
          </h2>
        </div>
        <ConnectionStatus
          isConnected={isConnected}
          isConnecting={isConnecting}
        />
      </div>

      {/* Message area */}
      <div className="flex-1 p-4 overflow-y-auto hide-scrollbar bg-gray-50">
        {messages.length === 0 ? (
          <WelcomeView
            repositoryName={repositoryName}
            isConnected={isConnected}
            isLoading={isLoading}
            isConnecting={isConnecting}
            presetQueries={presetQueries}
            onPresetQueryClick={handlePresetQueryClick}
          />
        ) : (
          <div className="space-y-5 max-w-4xl mx-auto">
            {/* Message bubbles */}
            {messages.map((message, index) => (
              <MessageBubble key={index} message={message} index={index} />
            ))}

            {/* Offline notice in chat */}
            {!isConnected && (
              <div className="flex justify-center message-animation my-6">
                <div className="bg-yellow-50 rounded-xl p-5 border border-yellow-200 max-w-[95%] shadow-md">
                  <OfflineMessage />
                </div>
              </div>
            )}

            <div ref={messagesEndRef} className="h-4" />
          </div>
        )}

        {/* Loading indicator */}
        {isLoading && <ThinkingIndicator />}
      </div>

      {/* Chat input area */}
      <div className="bg-white border-t border-gray-200 py-4">
        <div className="px-4 pb-4 max-w-4xl mx-auto">
          <form onSubmit={handleSubmit} className="relative mb-2">
            <div
              className={`flex items-end bg-white rounded-xl border border-gray-300 shadow-sm p-4 transition-all focus-within:border-custom-400 focus-within:ring-1 focus-within:ring-custom-400 ${!isConnected || isConnecting ? "opacity-50" : ""}`}
            >
              {/* Text input area */}
              <div className="flex-grow min-h-[48px]">
                <textarea
                  ref={textareaRef}
                  className="w-full resize-none border-none focus:ring-0 focus:outline-none bg-transparent px-2 py-2 max-h-[120px] min-h-[32px] text-custom-900 hide-scrollbar placeholder-gray-400"
                  placeholder={
                    isConnecting
                      ? "Connecting to chat service..."
                      : isConnected
                        ? `Ask about ${repositoryName}...`
                        : "Chat service is offline..."
                  }
                  value={question}
                  onChange={handleTextareaChange}
                  onKeyDown={handleKeyDown}
                  disabled={isLoading || !isConnected || isConnecting}
                  rows={1}
                />
              </div>

              {/* Action buttons */}
              <div className="flex items-center ml-2">
                {/* Emoji picker button */}
                <button
                  type="button"
                  onClick={handleEmojiClick}
                  className="p-3 text-custom-400 hover:text-custom-700 rounded-full transition-colors hover:bg-gray-100"
                  title="Add emoji"
                  disabled={!isConnected || isConnecting}
                >
                  <IconMoodSmile
                    size={24}
                    className={!isConnected || isConnecting ? "opacity-50" : ""}
                  />
                </button>

                {/* Send button */}
                <button
                  type="submit"
                  disabled={
                    isLoading ||
                    !question.trim() ||
                    !isConnected ||
                    isConnecting
                  }
                  className={`ml-2 p-3 rounded-full transition-all ${
                    isLoading ||
                    !question.trim() ||
                    !isConnected ||
                    isConnecting
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-custom-900 text-white hover:bg-custom-800 shadow-sm"
                  }`}
                >
                  {isLoading ? (
                    <div className="w-6 h-6 border-2 border-gray-300 border-t-transparent rounded-full animate-spin"></div>
                  ) : (
                    <IconArrowUp size={24} />
                  )}
                </button>
              </div>
            </div>

            {/* Emoji picker */}
            {showEmojiPicker && isConnected && !isConnecting && (
              <EmojiPicker
                onEmojiSelect={handleEmojiSelect}
                onClose={() => setShowEmojiPicker(false)}
              />
            )}
          </form>

          {/* Helper text */}
          <InputHelperText
            isConnected={isConnected}
            isConnecting={isConnecting}
          />
        </div>
      </div>
    </div>
  );
};
