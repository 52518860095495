import React, { useState } from "react";
import {
  IconFile,
  IconChevronRight,
  IconAlertCircle,
} from "@tabler/icons-react";
import {
  RepositoryFile,
  RepositoryFileStatus,
} from "../../data/data-contracts";

const FileTableLoader: React.FC = () => {
  return (
    <div className="space-y-4">
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className="bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden"
        >
          <div className="px-6 py-4 bg-gray-50/50 border-b border-gray-200">
            <div className="flex items-center gap-3">
              <IconFile className="w-5 h-5 text-gray-200 flex-shrink-0" />
              <div className="h-4 bg-gray-100 rounded-md w-3/4 animate-pulse"></div>
            </div>
          </div>
          <div className="px-6 py-5">
            <div className="flex flex-col gap-2.5">
              <div className="h-3 bg-gray-100 rounded-md w-full animate-pulse"></div>
              <div className="h-3 bg-gray-100 rounded-md w-4/5 animate-pulse"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

interface FileRowProps {
  file: RepositoryFile;
  index: number;
  isExpanded: boolean;
  onToggle: (index: number) => void;
}

const isFileExpandable = (status: RepositoryFileStatus): boolean => {
  return status === RepositoryFileStatus.COMPLETED;
};

const FileRow: React.FC<FileRowProps> = ({
  file,
  index,
  isExpanded,
  onToggle,
}) => {
  const expandable = isFileExpandable(file.status);

  const handleClick = () => {
    if (expandable) {
      onToggle(index);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (expandable && (e.key === "Enter" || e.key === " ")) {
      e.preventDefault();
      onToggle(index);
    }
  };

  return (
    <div className="bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden">
      <div
        className={`px-6 py-4 ${
          file.status === RepositoryFileStatus.FAILED
            ? "bg-red-50"
            : "bg-gray-50/50"
        } border-b border-gray-200 ${expandable ? "cursor-pointer hover:bg-gray-100/50" : ""} transition-colors`}
        onClick={handleClick}
        role={expandable ? "button" : undefined}
        tabIndex={expandable ? 0 : undefined}
        onKeyDown={handleKeyDown}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            {file.status === RepositoryFileStatus.FAILED ? (
              <IconAlertCircle className="w-5 h-5 text-red-500 flex-shrink-0" />
            ) : (
              <IconFile className="w-5 h-5 text-gray-400 flex-shrink-0" />
            )}
            <span
              className={`text-sm font-medium ${
                file.status === RepositoryFileStatus.FAILED
                  ? "text-red-600"
                  : "text-gray-700"
              }`}
              title={file.name}
            >
              {file.name}
            </span>
          </div>
          {expandable && (
            <IconChevronRight
              className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${
                isExpanded ? "rotate-90" : ""
              }`}
            />
          )}
        </div>
      </div>
      <div className="px-6 py-5">
        {file.status === RepositoryFileStatus.COMPLETED ? (
          <div>
            <div
              className={`text-sm text-gray-600 ${!isExpanded ? "line-clamp-2" : "whitespace-pre-line"}`}
            >
              {file.overview}
            </div>
          </div>
        ) : file.status === RepositoryFileStatus.FAILED ? (
          <div className="flex items-start gap-3 p-4 bg-red-50 rounded-lg border border-red-100">
            <IconAlertCircle className="w-5 h-5 text-red-500 flex-shrink-0 mt-0.5" />
            <div>
              <div className="font-medium text-red-700 mb-1">
                Analysis Failed
              </div>
              <div className="text-sm text-red-600">
                {file.failedReason ||
                  "An error occurred during file analysis. Please try again later."}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2.5">
            <div className="h-3 bg-gray-100 rounded-md w-full animate-pulse"></div>
            <div className="h-3 bg-gray-100 rounded-md w-4/5 animate-pulse"></div>
          </div>
        )}
      </div>
    </div>
  );
};

interface FilesTableProps {
  files: RepositoryFile[];
  isLoading?: boolean;
}

const FilesTable: React.FC<FilesTableProps> = ({
  files,
  isLoading = false,
}) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const toggleRow = (index: number) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  if (isLoading) {
    return <FileTableLoader />;
  }

  return (
    <div className="space-y-4">
      {files.map((file, index) => (
        <FileRow
          key={index}
          file={file}
          index={index}
          isExpanded={expandedRows.includes(index)}
          onToggle={toggleRow}
        />
      ))}
    </div>
  );
};

export default FilesTable;
