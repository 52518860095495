import { ReportType } from "../../types/general";
import { getIcon } from "../../utils/getLanguageIcon";
import ReportTabDownloadReport from "./ReportTabDownloadReport";

interface Props {
  report: ReportType;
  updated: Date;
}

export default function ReportTabGeneral(props: Props) {
  const { report, updated } = props;

  return (
    <>
      <ReportTabDownloadReport updated={updated} />
      <table className="min-w-full divide-y divide-custom-50 text-base leading-7">
        <thead className="">
          <tr>
            <th className="px-6 py-3 text-left font-medium text-custom-400 tracking-wider">
              Extension
            </th>
            <th className="px-6 py-3 text-left font-medium text-custom-400 tracking-wider">
              Number of Files
            </th>
            <th className="px-6 py-3 text-left font-medium text-custom-400 tracking-wider">
              Percentage
            </th>
          </tr>
        </thead>
        <tbody className="bg-white border-custom-50 border divide-y divide-custom-50">
          {Object.entries(report.generalStatistic.filesStatistic.fileTypes).map(
            ([fileType, fileData]) => (
              <tr key={fileType}>
                <td className="px-6 py-4 whitespace-nowrap font-medium text-custom-400">
                  {fileType}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-custom-400">
                  {fileData.count}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-custom-400">
                  {(
                    (fileData.count /
                      report.generalStatistic.filesStatistic.totalFilesCount) *
                    100
                  ).toFixed(2)}
                  %
                </td>
              </tr>
            )
          )}
          <tr>
            <td className="px-6 py-4 whitespace-nowrap font-semibold text-custom-900">
              Total
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-custom-900 font-semibold">
              {report.generalStatistic.filesStatistic.totalFilesCount}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-custom-400"></td>
          </tr>
        </tbody>
      </table>
      <div className="flex gap-3 items-center border-t border-custom-50 p-3">
        <div className="bg-custom-50 rounded h-14 w-14">
          {getIcon(report.generalStatistic.language)}
        </div>
        <div>
          <div className="text-custom-400">Main stack:</div>
          <div className="font-semibold text-custom-900">{`${report.generalStatistic.language} (${report.generalStatistic.extension})`}</div>
        </div>
      </div>
    </>
  );
}
