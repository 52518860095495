import React, { memo } from "react";

/**
 * Loading indicator shown while waiting for a response
 */
const ThinkingIndicator = memo(() => (
  <div className="flex justify-start mt-4 max-w-4xl mx-auto">
    <div className="bg-white text-custom-900 rounded-2xl p-4 shadow-sm border border-gray-100 message-bot pulse-animation">
      <div className="flex items-center space-x-2">
        <div className="w-2.5 h-2.5 bg-custom-300 rounded-full animate-bounce"></div>
        <div className="w-2.5 h-2.5 bg-custom-400 rounded-full animate-bounce delay-150"></div>
        <div className="w-2.5 h-2.5 bg-custom-500 rounded-full animate-bounce delay-300"></div>
        <span className="text-gray-500 text-sm ml-1">Thinking...</span>
      </div>
    </div>
  </div>
));

ThinkingIndicator.displayName = "ThinkingIndicator";

export default ThinkingIndicator;
