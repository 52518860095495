import React, { memo, useCallback } from "react";
import { EmojiPickerProps } from "./ChatTypes";

/**
 * EmojiPicker component for message input
 */
const EmojiPicker = memo(({ onEmojiSelect, onClose }: EmojiPickerProps) => {
  // Categorized emoji groups for easy access
  const emojiGroups = {
    thinking: ["❓", "🤔", "🧐", "🔍", "💭", "❔"],
    technical: ["💻", "🖥️", "⚙️", "🔧", "🔨", "🛠️"],
    data: ["📊", "📈", "📉", "💾", "💿", "🗄️"],
    documents: ["📝", "📋", "📑", "📌", "📎", "📂"],
    stages: ["🚧", "🏗️", "🧪", "✅", "❌", "🐛"],
    status: ["🔴", "🟠", "🟡", "🟢", "🔵", "⚫"],
    time: ["⏱️", "⏰", "📅", "⌛", "⏳", "🕒"],
    communication: ["💬", "📢", "🔔", "📨", "🔁", "👥"],
    reactions: ["👍", "👎", "👏", "🙌", "🤝", "💯"],
    solutions: ["🚨", "🔥", "💥", "✨", "💡", "🎯"],
  };

  // Flatten emoji groups into a single array
  const allEmojis = Object.values(emojiGroups).flat();

  const handleEmojiClick = useCallback(
    (emoji: string) => {
      onEmojiSelect(emoji);
      onClose();
    },
    [onEmojiSelect, onClose]
  );

  return (
    <div className="absolute bottom-full mb-3 right-0 bg-white rounded-xl shadow-lg border border-gray-200 p-4 w-80 h-64 overflow-y-auto hide-scrollbar">
      <div className="grid grid-cols-6 gap-3">
        {allEmojis.map((emoji, index) => (
          <button
            key={index}
            type="button"
            className="text-2xl hover:bg-gray-50 rounded p-2.5 flex items-center justify-center transition-colors"
            onClick={() => handleEmojiClick(emoji)}
          >
            {emoji}
          </button>
        ))}
      </div>
    </div>
  );
});

EmojiPicker.displayName = "EmojiPicker";

export default EmojiPicker;
