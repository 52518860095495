import { Transition, Dialog } from "@headlessui/react";
import {
  IconInnerShadowTop,
  IconPlugConnected,
  IconX,
} from "@tabler/icons-react";
import { Fragment } from "react";
import ConnectRepositoryRadioStep2 from "../ConnectRepositoryRadioStep2";
import { NavigateFunction } from "react-router-dom";
import { RepoResponseType } from "../../types/repositories";
import { postRepositories } from "../../api/repositories";

interface Props {
  setIsOpenStep2: (isOpenStep2: boolean) => void;
  isOpenStep2: boolean;
  setIsOpenStep1: (isOpenStep2: boolean) => void;
  repositories: RepoResponseType[];
  selected: RepoResponseType;
  setSelected: React.Dispatch<React.SetStateAction<RepoResponseType>>;
  projectId: number;
  navigate: NavigateFunction;
}

const ConnectRepositoryOverlayStep2 = (props: Props) => {
  const {
    setIsOpenStep1,
    setIsOpenStep2,
    isOpenStep2,
    repositories,
    selected,
    setSelected,
    projectId,
    navigate,
  } = props;

  const handleConnect = () => {
    postRepositories({
      repoName: selected.name,
      githubId: selected.id.toString(),
      projectId: projectId,
    }).then((res) => {
      if (res) {
        console.log(res);
        setIsOpenStep2(false);
        navigate(`/repository/${res.id}/overview`);
      }
    });
  };

  return (
    <Transition.Root show={isOpenStep2} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-10"
        onClose={() => setIsOpenStep2(false)}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-black/40" />

          <div className="fixed w-5/6 sm:w-1/2 xl:w-1/4 inset-y-0 right-0 flex pl-8">
            <Transition.Child
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 flex z-20">
                    <button
                      type="button"
                      className="text-custom-400"
                      onClick={() => setIsOpenStep2(false)}
                    >
                      <IconX className="h-8 w-8" />
                    </button>
                  </div>
                </Transition.Child>
                <div
                  id="styled-scroll"
                  className="flex flex-col justify-between bg-white shadow-xl overflow-y-auto rounded-l-2xl grow"
                >
                  <div
                    className="flex gap-8 flex-col justify-start items-center rounded bg-white"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="font-semibold text-lg m-2 self-start">
                      2/2
                    </div>
                    <div className="font-semibold text-2xl">
                      Connect repository
                    </div>
                    {repositories.length ? (
                      <>
                        <div className="px-4">
                          Choose the repository you want to connect.
                        </div>
                        <ConnectRepositoryRadioStep2
                          repositories={repositories}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      </>
                    ) : (
                      <div className="flex items-center justify-center mt-4">
                        <IconInnerShadowTop className="h-8 w-8 animate-spin mr-2" />
                        <span>Connecting...</span>
                      </div>
                    )}
                  </div>
                  <div className="p-10 border-t border-custom-50 flex justify-center items-center w-full flex-wrap gap-6">
                    <div
                      className="bg-white hover:bg-white text-custom-900 w-40 h-10 p-2 rounded-md text-center cursor-pointer border border-custom-50"
                      onClick={() => {
                        setIsOpenStep2(false);
                        setIsOpenStep1(true);
                      }}
                    >
                      Back
                    </div>
                    <div
                      className="bg-custom-900 hover:bg-gray-800 text-white w-40 h-10 p-2 rounded-md text-center cursor-pointer"
                      onClick={handleConnect}
                    >
                      <IconPlugConnected className="h-5 w-5 inline-block mr-2" />
                      <span>Connect</span>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConnectRepositoryOverlayStep2;
