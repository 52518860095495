import React from "react";
import { MarkdownComponentProps } from "./ChatTypes";

/**
 * Custom components for markdown rendering with proper styling
 */
export const markdownComponents = {
  code({
    node,
    inline,
    className,
    children,
    ...props
  }: MarkdownComponentProps) {
    const match = /language-(\w+)/.exec(className || "");
    const language = match ? match[1] : "";

    return !inline ? (
      <div className="relative">
        {language && (
          <div className="absolute top-0 right-0 bg-gray-700 text-xs text-gray-200 px-2 py-1 rounded-bl rounded-tr opacity-75">
            {language}
          </div>
        )}
        <pre className="rounded-md bg-gray-800 p-4 overflow-x-auto my-4">
          <code
            className={`text-sm text-gray-100 ${className || ""}`}
            {...props}
          >
            {String(children || "").replace(/\n$/, "")}
          </code>
        </pre>
      </div>
    ) : (
      <code
        className="bg-gray-200 text-gray-800 px-1 py-0.5 rounded font-mono text-sm"
        {...props}
      >
        {children}
      </code>
    );
  },
  pre({ children }: MarkdownComponentProps) {
    return <div className="w-full overflow-x-auto">{children}</div>;
  },
  h1: ({ children }: MarkdownComponentProps) => (
    <h1 className="text-xl font-bold my-3">{children}</h1>
  ),
  h2: ({ children }: MarkdownComponentProps) => (
    <h2 className="text-lg font-bold my-2">{children}</h2>
  ),
  h3: ({ children }: MarkdownComponentProps) => (
    <h3 className="text-md font-bold my-2">{children}</h3>
  ),
  ul: ({ children }: MarkdownComponentProps) => (
    <ul className="list-disc pl-6 my-2">{children}</ul>
  ),
  ol: ({ children }: MarkdownComponentProps) => (
    <ol className="list-decimal pl-6 my-2">{children}</ol>
  ),
  li: ({ children }: MarkdownComponentProps) => (
    <li className="my-1">{children}</li>
  ),
  p: ({ children }: MarkdownComponentProps) => (
    <p className="my-2">{children}</p>
  ),
  a: ({ href, children }: MarkdownComponentProps) => (
    <a
      href={href}
      className="text-blue-600 hover:underline"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ),
};
