import React, { memo } from "react";
import { IconWifi, IconWifiOff, IconLoader2 } from "@tabler/icons-react";
import { ConnectionStatusProps } from "./ChatTypes";

/**
 * Connection status indicator component for the header
 */
const ConnectionStatus = memo(
  ({ isConnected, isConnecting }: ConnectionStatusProps) =>
    isConnecting ? (
      <div className="flex items-center gap-1 text-blue-600 text-xs">
        <IconLoader2 size={14} className="animate-spin" />
        <span>Connecting...</span>
      </div>
    ) : isConnected ? (
      <div className="flex items-center gap-1 text-green-600 text-xs">
        <IconWifi size={14} />
        <span>Connected</span>
      </div>
    ) : (
      <div className="flex items-center gap-1 text-yellow-600 text-xs">
        <IconWifiOff size={14} />
        <span>Offline mode</span>
      </div>
    )
);

ConnectionStatus.displayName = "ConnectionStatus";

export default ConnectionStatus;
