import { IconChevronDown } from "@tabler/icons-react";
import { PropsWithChildren, useState } from "react";
import ReactMarkdown, { ExtraProps } from "react-markdown";
import {
  DocumentTool,
  DocumentationReport,
  ErrorObject,
  GeneralReport,
  ReportType,
} from "../../../types/general";
import { Explanation, highlightMarkdown, highlightText } from ".";

interface ShowDetailsType {
  errorHandlingReport: boolean;
  codeQualityReport: boolean;
  performanceReport: boolean;
  scalabilityReport: boolean;
  documentationReport: boolean;
}

interface Props {
  report: ReportType;
  reportData: GeneralReport | DocumentationReport;
  reportType: string;
  explanations: Explanation[];
  setShowChat: (value: boolean) => void;
  setExplanationId: React.Dispatch<React.SetStateAction<string | undefined>>;
  questionReportType?: string;
  source?: string;
  text?: string;
  explanationId?: string;
  showChat: boolean;
  setUncollapsedFiles: React.Dispatch<React.SetStateAction<string[]>>;
  uncollapsedFiles: string[];
}

export function FilesChecked({
  report,
  reportType,
  reportData,
  explanations,
  setShowChat,
  setExplanationId,
  questionReportType,
  source,
  explanationId,
  text,
  showChat,
  setUncollapsedFiles,
  uncollapsedFiles,
}: Props) {
  const [showDetails, setShowDetails] = useState<ShowDetailsType>({
    errorHandlingReport: false,
    codeQualityReport: false,
    performanceReport: false,
    scalabilityReport: false,
    documentationReport: false,
  });

  console.log("isDocToolsErr", reportData);

  const isDocToolsErr =
    (report.codeQuality?.documentationReport?.documentTools as ErrorObject)
      ?.error ||
    (report.codeQuality?.documentationReport?.documentTools as ErrorObject) ===
      undefined;

  const documentationFiles = [
    ...report.codeQuality?.documentationReport.comments.detailed.concat(
      report.codeQuality?.documentationReport.readmeReport
    ),
    ...[
      {
        fileName: "Technologies files",
        type: "library",
        explanation: "",
        recommendations: "",
        conclusion: "",
        error: false,
        score: "",
      },
    ],
  ];

  return (
    <div className="flex w-full text-base leading-7 gap-4">
      <div className="basis-[10%] shrink-0">Files checked:</div>
      <div className="basis-[90%]">
        {!showDetails[reportType as keyof ShowDetailsType] ? (
          reportType !== "documentationReport" ? (
            reportData.detailed
              ?.filter((obj) => !obj.error)
              ?.map((obj, index) => (
                <div key={index} className="flex gap-2">
                  {index === 0 && !obj.error && (
                    <>
                      {obj.fileName}
                      {reportData.detailed?.filter((obj) => !obj.error)
                        ?.length -
                        1 >
                      0 ? (
                        <div
                          className="flex items-center justify-center gap-2 cursor-pointer"
                          onClick={() =>
                            setShowDetails((prev) => ({
                              ...prev,
                              [reportType]: true,
                            }))
                          }
                        >
                          <div className="border border-custom-50 rounded px-1 ">
                            {` +${
                              reportData.detailed?.filter((obj) => !obj.error)
                                ?.length - 1
                            }`}
                          </div>
                          <div className="font-bold">Show details</div>
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            setShowDetails((prev) => ({
                              ...prev,
                              [reportType]: true,
                            }))
                          }
                          className="cursor-pointer font-bold"
                        >
                          Show details
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))
          ) : (
            Object.entries(reportData).map(
              ([reportTypeInner, reportDataInner]) =>
                reportTypeInner === "comments" &&
                (reportDataInner as GeneralReport).detailed
                  ?.filter((obj) => !obj.error)
                  ?.map((obj, index) => (
                    <div key={index} className="flex gap-2">
                      {index === 0 && !obj.error && (
                        <>
                          {obj.fileName}
                          {(reportDataInner as GeneralReport).detailed?.filter(
                            (obj) => !obj.error
                          )?.length -
                            1 >
                          0 ? (
                            <div
                              className="flex items-center justify-center gap-2 cursor-pointer"
                              onClick={() =>
                                setShowDetails((prev) => ({
                                  ...prev,
                                  [reportType]: true,
                                }))
                              }
                            >
                              <div className="border border-custom-50 rounded px-1 ">
                                {` +${
                                  (
                                    reportDataInner as GeneralReport
                                  ).detailed?.filter((obj) => !obj.error)
                                    ?.length +
                                  (!isDocToolsErr
                                    ? (
                                        report.codeQuality?.documentationReport
                                          ?.documentTools as DocumentTool[]
                                      )?.length
                                    : 0)
                                }`}
                              </div>
                              <div className="font-bold">Show details</div>
                            </div>
                          ) : (
                            <div
                              onClick={() =>
                                setShowDetails((prev) => ({
                                  ...prev,
                                  [reportType]: true,
                                }))
                              }
                              className="cursor-pointer font-bold"
                            >
                              Show details
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))
            )
          )
        ) : (
          <div>
            <div
              className="cursor-pointer font-bold mb-8"
              onClick={() => {
                setShowDetails((prev) => ({ ...prev, [reportType]: false }));
              }}
            >
              Hide details for {reportType}
            </div>
            {reportType !== "documentationReport"
              ? reportData.detailed
                  ?.filter((obj) => !obj.error)
                  ?.map((obj, index) => (
                    <div
                      key={index}
                      className="mb-8"
                      id={`source--${obj.fileName}`}
                    >
                      <div
                        onClick={() => {
                          setUncollapsedFiles((prev) =>
                            prev.includes(reportType + obj.fileName)
                              ? prev.filter(
                                  (file) => file !== reportType + obj.fileName
                                )
                              : [...prev, reportType + obj.fileName]
                          );
                        }}
                        className="flex items-center gap-2 cursor-pointer"
                      >
                        <div
                          className={`bg-custom-50 rounded p-1 duration-500 ${
                            uncollapsedFiles.includes(reportType + obj.fileName)
                              ? "rotate-180"
                              : "rotate-0"
                          }`}
                        >
                          <IconChevronDown />
                        </div>
                        <div className="text-lg">{obj.fileName}</div>
                      </div>
                      {uncollapsedFiles.includes(reportType + obj.fileName) && (
                        <>
                          <div className="mt-2 flex gap-2">
                            <div className="text-custom-400 font-bold">
                              Score:
                            </div>
                            <div>{obj.score}</div>
                          </div>
                          <div className="mt-2">
                            <div className="text-custom-400 font-bold">
                              Explanations:
                            </div>
                            {/* <div><ReactMarkdown>{obj.explanation}</ReactMarkdown></div> */}
                            <div>
                              <ReactMarkdown
                                components={highlightMarkdown({
                                  explanations,
                                  explanationId,
                                  setExplanationId,
                                  setShowChat,
                                })}
                              >
                                {highlightText({
                                  text: `${obj.explanation}`,
                                  reportType,
                                  explanations,
                                  source: obj.fileName,
                                  newQuestionFields: {
                                    reportType: questionReportType as string,
                                    source: source as string,
                                    textContent: text as string,
                                  },
                                  showingChat: showChat,
                                })}
                              </ReactMarkdown>
                            </div>
                          </div>
                          <div className="mt-2">
                            <div className="text-custom-400 font-bold">
                              Recommendations:
                            </div>
                            {/* <div><ReactMarkdown>{obj.recommendations}</ReactMarkdown></div> */}
                            <div>
                              <ReactMarkdown
                                components={highlightMarkdown({
                                  explanations,
                                  explanationId,
                                  setExplanationId,
                                  setShowChat,
                                })}
                              >
                                {highlightText({
                                  text: `${obj.recommendations}`,
                                  reportType,
                                  explanations,
                                  source: obj.fileName,
                                  newQuestionFields: {
                                    reportType: questionReportType as string,
                                    source: source as string,
                                    textContent: text as string,
                                  },
                                  showingChat: showChat,
                                })}
                              </ReactMarkdown>
                            </div>
                          </div>
                          <div className="mt-2">
                            <div className="text-custom-400 font-bold">
                              Conclusion:
                            </div>
                            <div>
                              {/* <ReactMarkdown>{obj.conclusion}</ReactMarkdown> */}

                              <ReactMarkdown
                                components={highlightMarkdown({
                                  explanations,
                                  explanationId,
                                  setExplanationId,
                                  setShowChat,
                                })}
                              >
                                {highlightText({
                                  text: `${obj.conclusion}`,
                                  reportType,
                                  explanations,
                                  source: obj.fileName,
                                  newQuestionFields: {
                                    reportType: questionReportType as string,
                                    source: source as string,
                                    textContent: text as string,
                                  },
                                  showingChat: showChat,
                                })}
                              </ReactMarkdown>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))
              : documentationFiles
                  .filter((obj) => !obj.error)
                  ?.map((obj, index) => (
                    <div
                      key={index}
                      className="mb-8"
                      id={`source--${obj.fileName}`}
                    >
                      <div
                        onClick={() => {
                          setUncollapsedFiles((prev) =>
                            prev.includes(reportType + obj.fileName)
                              ? prev.filter(
                                  (file) => file !== reportType + obj.fileName
                                )
                              : [...prev, reportType + obj.fileName]
                          );
                        }}
                        className="flex items-center gap-2 cursor-pointer"
                      >
                        <div
                          className={`bg-custom-50 rounded p-1 duration-500 ${
                            uncollapsedFiles.includes(reportType + obj.fileName)
                              ? "rotate-180"
                              : "rotate-0"
                          }`}
                        >
                          <IconChevronDown />
                        </div>
                        <div className="text-lg">{obj.fileName}</div>
                      </div>
                      {uncollapsedFiles.includes(reportType + obj.fileName) &&
                      obj.fileName !== "Technologies files" ? (
                        <>
                          <div className="mt-2 flex gap-2">
                            <div className="text-custom-400 font-bold">
                              Score:
                            </div>
                            <div>{obj?.score}</div>
                          </div>
                          <div className="mt-2">
                            <div className="text-custom-400 font-bold">
                              Explanations:
                            </div>
                            <div>
                              <ReactMarkdown>{obj.explanation}</ReactMarkdown>
                            </div>
                          </div>
                          <div className="mt-2">
                            <div className="text-custom-400 font-bold">
                              Recommendations:
                            </div>
                            <div>
                              <ReactMarkdown>
                                {obj.recommendations}
                              </ReactMarkdown>
                            </div>
                          </div>
                          <div className="mt-2">
                            <div className="text-custom-400 font-bold">
                              Conclusion:
                            </div>
                            <div>
                              <ReactMarkdown>{obj.conclusion}</ReactMarkdown>
                            </div>
                          </div>
                        </>
                      ) : (
                        uncollapsedFiles.includes(reportType + obj.fileName) &&
                        obj.fileName === "Technologies files" && (
                          <table className="min-w-full divide-y divide-custom-50">
                            {!isDocToolsErr ? (
                              <>
                                <thead className="">
                                  <tr>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-custom-400 tracking-wider">
                                      Technology
                                    </th>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-custom-400 tracking-wider">
                                      Description
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white border-custom-50 border divide-y divide-custom-50">
                                  {(
                                    report.codeQuality?.documentationReport
                                      ?.documentTools as DocumentTool[]
                                  ).map((technology) => (
                                    <tr
                                      key={technology.technologyName}
                                      style={{
                                        borderBottom: "1px solid #E8ECEF",
                                      }}
                                    >
                                      <td className="px-6 py-4 text-sm font-medium text-custom-900">
                                        {technology.technologyName} (
                                        {technology.type})
                                      </td>
                                      <td className="px-6 py-4 text-sm text-custom-900">
                                        {technology.explanation}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </>
                            ) : (
                              <tbody>
                                <tr>
                                  <td
                                    colSpan={2}
                                    className="px-6 py-4 text-sm font-normal text-custom-900 text-center"
                                  >
                                    Error:{" "}
                                    {(
                                      report.codeQuality?.documentationReport
                                        ?.documentTools as ErrorObject
                                    )?.error ||
                                      "An error occurred while loading technologies or language is not supported for checking used technologies."}
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        )
                      )}
                    </div>
                  ))}
          </div>
        )}
      </div>
    </div>
  );
}
