import {
  IconLock,
  IconHistory,
  IconAlertCircle,
  IconCrown,
  IconChevronRight,
} from "@tabler/icons-react";
import { useUserStore } from "../../../store/userStore";
import { Audit, AuditStatus, UserStatus } from "../../../data/data-contracts";
import { RepositoryOverviewStatus } from "../../../data/data-contracts";
import { useNavigate } from "react-router-dom";

interface AuditHistoryProps {
  inProgress: boolean;
  isFailed: boolean;
  status: RepositoryOverviewStatus;
  onRequestAudit?: () => void;
  setIsLanguageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  repositoryId: string;
  repositoryName: string;
  auditStatus?: AuditStatus | null;
  lastAuditDate?: string | null;
  audits?: Audit[] | null;
}

export const AuditHistory = ({
  onRequestAudit,
  inProgress,
  isFailed,
  status,
  setIsLanguageModalOpen,
  repositoryId,
  repositoryName,
  auditStatus,
  lastAuditDate,
  audits,
}: AuditHistoryProps) => {
  const navigate = useNavigate();
  const { user } = useUserStore();

  if (inProgress || isFailed) return null;

  const getPlanContent = () => {
    switch (user?.status) {
      case UserStatus.Premium:
        return {
          icon: <IconCrown className="w-4 h-4" strokeWidth={2} />,
          text: "Premium Access",
          isClickable: false,
        };
      case UserStatus.PremiumRequested:
        return {
          icon: <IconAlertCircle className="w-4 h-4" strokeWidth={2} />,
          text: "Premium Requested",
          isClickable: false,
        };
      default:
        return {
          icon: <IconLock className="w-4 h-4" strokeWidth={2} />,
          text: "Request Full Audit",
          isClickable: true,
        };
    }
  };

  const planContent = getPlanContent();

  const handleAuditHistoryClick = () => {
    console.log(audits);
    navigate(`/code-audits-history/${repositoryId}`);
  };

  return (
    <div className="w-64 h-[120px] bg-white rounded-2xl shadow-sm overflow-hidden border border-gray-200">
      <div className="flex flex-col h-full">
        {/* History Section */}
        <button
          onClick={handleAuditHistoryClick}
          className="h-1/2 group flex items-center justify-between px-4 py-4 hover:bg-gray-50 transition-colors"
        >
          <div className="flex items-center gap-2">
            <div className="p-1 rounded-full bg-gray-100 group-hover:bg-white">
              <IconHistory
                className="w-4 h-4 text-gray-600 group-hover:text-gray-900"
                strokeWidth={2}
              />
            </div>
            <span className="text-sm font-medium text-gray-600 group-hover:text-gray-900">
              Audit History
            </span>
          </div>
          <IconChevronRight
            className="w-4 h-4 text-gray-400 group-hover:text-gray-900 group-active:translate-x-0.5 transition-all"
            strokeWidth={2}
          />
        </button>

        {/* Divider */}
        <div className="h-[1px] bg-gray-100" />

        {/* Status Section */}
        {user?.status !== UserStatus.Premium ? (
          <div
            className={`flex items-center justify-between px-4 py-4 ${
              planContent.isClickable
                ? "cursor-pointer hover:bg-gray-50 transition-colors"
                : ""
            }`}
            onClick={() => {
              if (planContent.isClickable && onRequestAudit) {
                onRequestAudit();
              }
            }}
          >
            <div className="flex items-center gap-2">
              <div className="p-1 rounded-full bg-gray-100">
                <div className="text-gray-600">{planContent.icon}</div>
              </div>
              <span className="text-sm font-medium text-gray-700">
                {planContent.text}
              </span>
            </div>
            {planContent.isClickable && (
              <IconChevronRight
                className="w-4 h-4 text-gray-400"
                strokeWidth={2}
              />
            )}
            {!planContent.isClickable && (
              <div className="w-1 h-1 rounded-full bg-gray-300"></div>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-between w-full h-full">
            <button
              className={`m-auto w-full mx-4 ${
                auditStatus !== AuditStatus.InProgress
                  ? "bg-custom-900 hover:bg-gray-800 text-white cursor-pointer"
                  : " bg-custom-300 text-custom-900 cursor-default"
              } w-44 h-8 p-2 rounded-md text-center text-xs`}
              onClick={(event) => {
                event.stopPropagation();
                setIsLanguageModalOpen(true);
              }}
              disabled={auditStatus === AuditStatus.InProgress}
            >
              {auditStatus === AuditStatus.Failed ||
              auditStatus === AuditStatus.Completed ||
              !auditStatus
                ? "Request Code Audit"
                : `Code Audit: ${auditStatus}`}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
