import getScoreIcon from "../../../utils/getScoreIcon";

import { UserMe, UserStatus } from "../../../data/data-contracts";
import { IconLock } from "@tabler/icons-react";

export default function ReportEmptyState({ user }: { user: UserMe }) {
  if (user?.status === UserStatus.Premium) {
    return null;
  }

  return (
    <div className="relative">
      <section
        className={`flex flex-row items-start relative mb-4 gap-2 ${user?.status !== UserStatus.Premium ? "blur-[3px]" : ""}`}
      >
        <div className="flex-1 flex justify-start items-stretch gap-4 border border-custom-50 rounded-xl py-4">
          <div className="basis-[15%] flex flex-col items-center justify-start gap-2 shrink-0 pt-4">
            <img src={getScoreIcon(8)} alt="score" />
            <p>Code Quality</p>
          </div>
          <div className="basis-[80%] flex flex-col items-start justify-between gap-5 text-sm text-custom-400">
            <div className="flex w-full text-base leading-7 gap-4 pr-2">
              <div className="basis-[10%] shrink-0">Conclusion:</div>
              <div
                className="basis-[90%] relative text-justify"
                id="source--conclusion"
              >
                <p>
                  The codebase, consisting of Ruby on Rails migration scripts
                  and a Ruby snippet extending the Spree::Order class, exhibits
                  a good level of adherence to best practices, demonstrating
                  proficiency in leveraging ActiveRecord migrations, module
                  inclusion, and ActiveRecord associations within the context of
                  the Spree e-commerce platform.
                </p>
                <p>
                  However, there are recurring areas for improvement across the
                  codebase: Lack of comprehensive documentation and comments,
                  specifically detailed explanations for new columns in
                  migration scripts and for the purpose of specific
                  functionalities like custom checkout flows or individual
                  columns. Absence of error handling and safety checks,
                  especially in the migration scripts where checks for existing
                  columns could enhance safety. Also, the need for comprehensive
                  error handling in production-grade functionalities was
                  highlighted. The presence of commented-out code and potential
                  for complexity arising from chaining associations were noted
                  as areas that could confuse maintenance and impact code
                  readability or performance.
                </p>
                <p>
                  With focused attention on these areas, particularly in
                  enhancing documentation, implementing thorough error handling,
                  and ensuring clarity and performance in association
                  management, the code quality across the codebase could be
                  further improved. The average score of approximately 7.67
                  reflects a good baseline with significant potential for
                  achieving higher standards of excellence and robustness in the
                  codebase.
                </p>
              </div>
            </div>
            <div className="flex w-full text-base leading-7 gap-4">
              <div className="basis-[10%] shrink-0">Files checked:</div>
              <div className="basis-[90%]">
                <div className="flex gap-2">
                  db/migrate/20170913151609_add_code_to_spree_promotion_categories.spree.rb
                  <div className="flex items-center justify-center gap-2 cursor-pointer">
                    <div className="border border-custom-50 rounded px-1 ">
                      {" "}
                      +2
                    </div>
                    <div className="font-bold">Show details</div>
                  </div>
                </div>
                <div className="flex gap-2"></div>
                <div className="flex gap-2"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {user?.status !== UserStatus.Premium && (
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <div className="p-6 rounded-full bg-white/95 shadow-xl">
            <IconLock size={48} className="text-custom-400" />
          </div>
        </div>
      )}
    </div>
  );
}
