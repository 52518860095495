import {
  IconBrandGithub,
  IconMessageCircle,
  IconRefresh,
  IconLoader2,
} from "@tabler/icons-react";
import {
  RepositoryOverview,
  VectorRepositoryStatus,
} from "../../../data/data-contracts";
import {
  useVectorRepository,
  useVectorRepositorySync,
} from "../../../api/repositories";
import { useEffect, useState } from "react";

interface GitHubInfoProps {
  organization?: string;
  repository?: string;
  repositoryOverview?: RepositoryOverview;
  onOpenChat: () => void;
}

export const GitHubInfo = ({
  organization,
  repository,
  repositoryOverview,
  onOpenChat,
}: GitHubInfoProps) => {
  const isLoading = !organization || !repository;

  const [showChatButton, setShowChatButton] = useState(
    repositoryOverview?.vectorRepository &&
      repositoryOverview?.vectorRepository?.status ===
        VectorRepositoryStatus.COMPLETED
  );
  const [showPendingStatus, setShowPendingStatus] = useState(
    repositoryOverview?.vectorRepository &&
      repositoryOverview?.vectorRepository?.status ===
        VectorRepositoryStatus.IN_PROGRESS
  );

  const [showErrorStatus, setShowErrorStatus] = useState(
    repositoryOverview?.vectorRepository &&
      repositoryOverview?.vectorRepository?.status ===
        VectorRepositoryStatus.FAILED
  );

  const { vectorRepository } = useVectorRepository(
    repositoryOverview?.id!,
    !!showPendingStatus
  );

  useEffect(() => {
    if (!!vectorRepository && repositoryOverview?.id) {
      setShowChatButton(
        vectorRepository !== null &&
          vectorRepository.status === VectorRepositoryStatus.COMPLETED
      );
      setShowPendingStatus(
        vectorRepository !== null &&
          vectorRepository.status === VectorRepositoryStatus.IN_PROGRESS
      );
      setShowErrorStatus(
        vectorRepository !== null &&
          vectorRepository.status === VectorRepositoryStatus.FAILED
      );
    }

    if (!vectorRepository) {
      setShowChatButton(false);
      setShowPendingStatus(true);
      setShowErrorStatus(false);
    }
  }, [vectorRepository, repositoryOverview?.id]);

  const { mutateAsync: syncVectorRepository } = useVectorRepositorySync(
    repositoryOverview?.id!
  );

  const handleSyncVectorRepository = async () => {
    await syncVectorRepository();
  };

  return (
    <div className="inline-block w-auto h-[120px] bg-gradient-to-r from-gray-50 to-white rounded-xl border border-gray-200 shadow-sm overflow-hidden relative">
      <div className="h-full flex items-center justify-between">
        {/* Left side with GitHub info */}
        <div className="flex items-center h-full pl-5">
          {isLoading ? (
            <div className="flex items-center space-x-4">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse" />
              <div className="space-y-2">
                <div className="w-[180px] h-5 bg-gray-200 animate-pulse rounded-md" />
                <div className="w-[120px] h-4 bg-gray-200 animate-pulse rounded-md" />
              </div>
            </div>
          ) : (
            <div className="flex items-center space-x-4">
              <div className="w-10 h-10 flex items-center justify-center bg-white rounded-full shadow-sm border border-gray-100">
                <IconBrandGithub className="w-6 h-6 text-[#24292F]" />
              </div>
              <div>
                <div className="flex items-center text-sm font-medium">
                  <span className="text-gray-800 hover:text-blue-600 cursor-pointer transition-colors">
                    {organization}
                  </span>
                  <span className="mx-1.5 text-gray-400">/</span>
                  <span className="text-gray-800 hover:text-blue-600 cursor-pointer transition-colors">
                    {repository}
                  </span>
                </div>
                <div className="text-xs text-gray-500 mt-0.5">
                  GitHub Repository
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Right side with buttons */}
        <div className="h-full flex items-center pr-5 pl-2">
          <div className="flex flex-col items-end">
            <div className="flex items-center space-x-2">
              {showChatButton && (
                <button
                  className="group flex items-center justify-center gap-1.5 px-3 h-9 rounded-lg bg-gray-900 hover:bg-black text-white shadow-sm transition-all"
                  aria-label="Open chat"
                  onClick={onOpenChat}
                >
                  <IconMessageCircle className="w-5 h-5" />
                  <span className="text-sm font-medium">Chat</span>
                </button>
              )}
              {showPendingStatus && (
                <button
                  className="group flex items-center justify-center gap-1.5 px-3 h-9 rounded-lg bg-gray-400 text-white shadow-sm transition-all cursor-not-allowed"
                  aria-label="Processing"
                  disabled
                >
                  <IconLoader2 className="w-5 h-5 animate-spin" />
                  <span className="text-sm font-medium">Chat</span>
                </button>
              )}
              <button
                className="group flex items-center justify-center w-9 h-9 rounded-lg bg-white border border-gray-200 hover:border-blue-200 hover:bg-blue-50 shadow-sm transition-all"
                aria-label="Sync"
                onClick={handleSyncVectorRepository}
              >
                <IconRefresh className="w-5 h-5 text-gray-500 group-hover:text-blue-600 transition-colors" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Absolute positioned status message */}
      {showPendingStatus && (
        <div className="absolute bottom-2 left-0 right-0 text-xs text-gray-500 text-center">
          Please wait, vectorization process in progress. This may take several
          minutes.
        </div>
      )}

      {/* Error status message */}
      {showErrorStatus && (
        <div className="absolute bottom-2 left-0 right-0 text-xs text-red-500 text-center">
          Chat is not available. Please sync repository again.
        </div>
      )}
    </div>
  );
};
