import React, { memo } from "react";
import { IconAlertTriangle } from "@tabler/icons-react";

/**
 * Offline notification component that displays a warning when chat is offline
 */
const OfflineMessage = memo(() => (
  <div className="flex items-start gap-4">
    <IconAlertTriangle
      className="text-yellow-500 flex-shrink-0 mt-0.5"
      size={24}
    />
    <div>
      <h4 className="font-medium text-yellow-800 mb-2 text-base">
        Service Currently Unavailable
      </h4>
      <p className="text-yellow-700 text-sm leading-relaxed">
        The chat service is currently offline. Please try again later when the
        connection is restored.
      </p>
    </div>
  </div>
));

OfflineMessage.displayName = "OfflineMessage";

export default OfflineMessage;
