import { createSlice } from "@reduxjs/toolkit";

interface userState {
  isLogged: boolean;
  email: string;
  currentRepo: string;
}

const initialState: userState = {
  isLogged: false,
  email: "",
  currentRepo: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogged: (state) => {
      state.isLogged = true;
    },

    setEmail: (state, { payload }: { payload: string }) => {
      state.email = payload;
    },

    setLogout: (state) => {
      localStorage.clear();
      state.isLogged = false;
    },

    setCurrentRepo: (state, { payload }: { payload: string }) => {
      state.currentRepo = payload;
    },
  },
});

export const { setLogged, setLogout, setEmail, setCurrentRepo } =
  userSlice.actions;

export default userSlice.reducer;
