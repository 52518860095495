import React from "react";
import TopBar from "../TopBar";

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="w-full px-10 py-2">
        <TopBar />
      </div>
      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
};
