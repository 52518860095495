/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CreateProjectRequest, CreateProjectResponse, Error, ListProjectsResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Projects<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get a list of all projects belonging to the authenticated user
   *
   * @tags Project
   * @name ListProjects
   * @summary Get user's projects
   * @request GET:/projects
   * @secure
   */
  listProjects = (params: RequestParams = {}) =>
    this.request<ListProjectsResponse, Error>({
      path: `/projects`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create a new project for the authenticated user
   *
   * @tags Project
   * @name CreateProject
   * @summary Create a new project
   * @request POST:/projects
   * @secure
   */
  createProject = (data: CreateProjectRequest, params: RequestParams = {}) =>
    this.request<CreateProjectResponse, Error>({
      path: `/projects`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
