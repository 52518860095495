import { useMutation, useQuery } from "@tanstack/react-query";
import { API } from "../../data";
import {
  RepositoryOverview,
  RepositoryOverviewSocketEventContent,
  RepositoryOverviewStatus,
} from "../../data/data-contracts";
import { useRepositoryOverviewSocket } from "../websocket/useRepositoryOverviewSocket";
import { useRepositoryOverviewStore } from "../../store/repositoryOverview";
import { useEffect } from "react";
import axios from "axios";

export enum RepositoryOverviewQueryKey {
  GetOverview = "getOverview",
}

export const useGetRepositoryOverview = (repoId?: string) => {
  const { setInProgress, setIsFailed, setValues } =
    useRepositoryOverviewStore();

  const query = useQuery<RepositoryOverview, Error>({
    queryKey: [RepositoryOverviewQueryKey.GetOverview, repoId],
    queryFn: async () => {
      try {
        const response = await API.repositoryOverview.getRepositoryOverview(
          repoId!
        );

        return response.data as RepositoryOverview;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          throw new Error("NOT_FOUND");
        }
        throw new Error("SOMETHING_WENT_WRONG");
      }
    },
    enabled: !!repoId,
    staleTime: 0,
    retry: false,
  });

  // connect to websocket only if overview is not completed or failed
  useRepositoryOverviewSocket(
    Number(repoId),
    query.isLoading,
    query.data?.status
  );

  useEffect(() => {
    if (query.data) {
      setValues(query.data as unknown as RepositoryOverviewSocketEventContent);
    }

    if (query.data?.status === RepositoryOverviewStatus.FAILED) {
      setIsFailed(true);
    }

    if (query.data?.status === RepositoryOverviewStatus.COMPLETED) {
      setInProgress(false);
    }
  }, [query.data, setValues, setIsFailed, setInProgress]);

  return query;
};

export const useRefreshRepositoryOverviewMutation = (repositoryId: string) => {
  const { setInProgress, setIsFailed, setValues, reset } =
    useRepositoryOverviewStore();

  const mutation = useMutation<RepositoryOverview, Error, string>({
    mutationFn: async (repoId: string) => {
      try {
        reset();
        const response = await API.repositoryOverview.refreshRepositoryOverview(
          Number(repoId)
        );

        return response.data as RepositoryOverview;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          throw new Error("NOT_FOUND");
        }
        throw new Error("SOMETHING_WENT_WRONG");
      }
    },
    onSuccess: (data) => {
      setValues(data as unknown as RepositoryOverviewSocketEventContent);

      if (data.status === RepositoryOverviewStatus.FAILED) {
        setIsFailed(true);
      }

      if (data.status === RepositoryOverviewStatus.COMPLETED) {
        setInProgress(false);
      }
    },
  });

  // Connect to websocket when mutation is loading and not completed/failed
  useRepositoryOverviewSocket(
    Number(repositoryId),
    mutation.status === "idle",
    mutation.data?.status
  );

  return mutation;
};
