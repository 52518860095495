import React, { forwardRef, useState } from "react";
import { IconAlertCircle } from "@tabler/icons-react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  leftIcon?: React.ReactNode;
  className?: string;
  containerClassName?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      leftIcon,
      className = "",
      containerClassName = "",
      onFocus,
      onBlur,
      disabled,
      value,
      placeholder,
      id,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const isActive = isFocused || !!value;
    const inputId = id || Math.random().toString(36).substring(7);

    return (
      <div className={`relative ${containerClassName}`}>
        <div className="relative">
          <input
            {...props}
            id={inputId}
            ref={ref}
            disabled={disabled}
            value={value}
            placeholder={isActive ? placeholder : ""}
            className={`
              peer w-full h-10 bg-white text-sm text-gray-900 rounded-lg pr-4
              border transition-all duration-200
              disabled:opacity-50 disabled:cursor-not-allowed
              ${leftIcon ? "pl-11" : "pl-4"}
              ${
                error
                  ? "border-red-500 focus:border-red-500 focus:ring-red-500"
                  : "border-gray-200 hover:border-gray-300 focus:border-custom-900 focus:ring-custom-900"
              }
              focus:outline-none focus:ring-2 focus:ring-offset-0
              ${className}
            `}
            onFocus={(e) => {
              setIsFocused(true);
              onFocus?.(e);
            }}
            onBlur={(e) => {
              setIsFocused(false);
              onBlur?.(e);
            }}
          />
          {leftIcon && (
            <span
              className={`absolute left-3 top-1/2 transform -translate-y-1/2 transition-colors duration-200 pointer-events-none
                ${
                  error
                    ? "text-red-500"
                    : isActive
                      ? "text-custom-900"
                      : "text-gray-400"
                }
                ${disabled ? "opacity-50" : ""}
              `}
            >
              {leftIcon}
            </span>
          )}
          {label && (
            <label
              htmlFor={inputId}
              className={`absolute text-xs font-medium tracking-wide transition-all duration-200 select-none
                ${
                  error
                    ? "text-red-500"
                    : isActive
                      ? "text-custom-900"
                      : "text-gray-400"
                }
                ${
                  isActive
                    ? "-top-2 left-2 px-1 bg-white z-10"
                    : `top-1/2 ${leftIcon ? "left-11" : "left-4"} -translate-y-1/2`
                }
                ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-text"}
                peer-focus:-top-2 peer-focus:left-2 peer-focus:px-1 peer-focus:bg-white peer-focus:z-10
                peer-focus:${error ? "text-red-500" : "text-custom-900"}
              `}
            >
              {isActive ? label : placeholder || label}
            </label>
          )}
        </div>
        {error && (
          <div className="mt-1.5 flex items-center gap-1.5 text-red-500">
            <IconAlertCircle size={14} />
            <p className="text-xs leading-tight">{error}</p>
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
