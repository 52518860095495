import { IconArrowDown } from "@tabler/icons-react";

interface ScrollToSummaryButtonProps {
  onClick: () => void;
}

export const ScrollToSummaryButton = ({
  onClick,
}: ScrollToSummaryButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="fixed left-1/2 bottom-8 transform -translate-x-1/2 z-50 bg-gray-900 hover:bg-gray-800 text-white rounded-full w-12 h-12 shadow-lg transition-all duration-300 hover:scale-110 flex items-center justify-center animate-pulse"
    >
      <IconArrowDown className="w-6 h-6" />
    </button>
  );
};
