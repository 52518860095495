import React, { memo } from "react";
import { IconClock } from "@tabler/icons-react";
import ReactMarkdown from "react-markdown";
import { MessageProps } from "./ChatTypes";
import { markdownComponents } from "./MarkdownRenderer";
import { formatMessageTime } from "./ChatUtils";

/**
 * Message bubble component for individual messages
 */
const MessageBubble = memo(({ message, index }: MessageProps) => (
  <div
    className={`flex ${
      message.type === "user" ? "justify-end" : "justify-start"
    } message-animation`}
    style={{ animationDelay: `${index * 0.1}s` }}
  >
    <div className="flex flex-col">
      <div
        className={`max-w-[85%] rounded-2xl p-4 shadow-sm ${
          message.type === "user"
            ? "bg-emerald-500 text-white message-user"
            : "bg-white text-custom-900 message-bot border border-gray-100"
        }`}
      >
        {message.type === "user" ? (
          <div className="font-medium">{message.message}</div>
        ) : (
          <ReactMarkdown components={markdownComponents}>
            {message.message}
          </ReactMarkdown>
        )}
      </div>

      {/* Timestamp display */}
      <div
        className={`flex items-center mt-1 text-xs ${
          message.type === "user" ? "justify-end mr-2" : "justify-start ml-2"
        }`}
      >
        <IconClock size={12} className="mr-1 text-gray-400" />
        <span className="text-gray-400">
          {formatMessageTime(message.timestamp) ||
            formatMessageTime(new Date())}
        </span>
      </div>
    </div>
  </div>
));

MessageBubble.displayName = "MessageBubble";

export default MessageBubble;
