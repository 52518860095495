import { IconCode } from "@tabler/icons-react";
import { useMemo } from "react";
import { Label } from "./Label";

interface TechStackProps {
  inProgress: boolean;
  data: string[];
}

const TechStackLoading = () => (
  <div className="flex flex-wrap gap-2">
    {[1, 2, 3, 4, 5].map((index) => (
      <div
        key={index}
        className="h-7 w-20 rounded-full bg-gray-100 animate-pulse"
      />
    ))}
  </div>
);

export const TechStack = ({ inProgress, data }: TechStackProps) => {
  const renderContent = useMemo(() => {
    switch (true) {
      case inProgress:
        return <TechStackLoading />;
      case data.length === 0:
        return (
          <div className="p-4 rounded-lg bg-gray-50 border border-gray-200">
            <p className="text-gray-600 text-sm">
              No technology stack has been detected for this repository.
            </p>
          </div>
        );
      default:
        return (
          <div className="flex flex-wrap gap-2">
            {data.map((tech, index) => (
              <div
                key={index}
                className="px-3 py-1 rounded-full text-sm font-medium bg-gray-900 text-gray-100 transition-all hover:scale-105 hover:bg-gray-800"
              >
                {tech}
              </div>
            ))}
          </div>
        );
    }
  }, [inProgress, data]);

  return (
    <div className="flex flex-col gap-6">
      <Label icon={IconCode}>Tech Stack</Label>
      {renderContent}
    </div>
  );
};
