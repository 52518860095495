import { IconCheck, IconRefresh, IconX } from "@tabler/icons-react";
import { useRefreshRepositoryOverviewMutation } from "../../../api/repository-overview/repository-overview";

interface AnalysisProgressProps {
  inProgress: boolean;
  isFailed: boolean;
  step: number;
  progress: number;
  repoId: string;
}

const getAnalysisMessages = (
  step: number,
  inProgress: boolean,
  isFailed: boolean
) => {
  if (isFailed) {
    return [
      {
        text: "Full repository overview analysis failed",
        completed: true,
        show: true,
        error: true,
      },
    ];
  }

  if (!inProgress) {
    return [
      {
        text: "Repository overview is ready",
        completed: true,
        show: true,
        error: false,
      },
    ];
  }

  const messages = [
    {
      text: "Analyzing repository structure and files...",
      completed: step > 1,
      show: step >= 0,
      error: false,
    },
    {
      text: "Analyzing most important files...",
      completed: step >= 4,
      show: step >= 2,
      error: false,
    },
    {
      text: "Summarization in progress...",
      completed: step > 4,
      show: step >= 4,
      error: false,
    },
  ];

  return messages.filter((msg) => msg.show);
};

export const AnalysisProgress = ({
  inProgress,
  isFailed,
  step,
  progress,
  repoId,
}: AnalysisProgressProps) => {
  const refreshMutation = useRefreshRepositoryOverviewMutation(repoId);

  const colorScheme = isFailed
    ? {
        bg: "bg-red-50",
        border: "border-red-100",
        text: "text-red-600",
        textDark: "text-red-700",
        bgLight: "bg-red-100",
        bgDark: "bg-red-500",
        gradient: "from-red-500 via-red-400 to-red-500",
        shadow: "rgba(239, 68, 68, 0.2)",
      }
    : inProgress
      ? {
          bg: "bg-orange-50",
          border: "border-orange-100",
          text: "text-orange-600",
          textDark: "text-orange-700",
          bgLight: "bg-orange-100",
          bgDark: "bg-orange-500",
          gradient: "from-orange-500 via-orange-400 to-orange-500",
          shadow: "rgba(251, 146, 60, 0.2)",
        }
      : {
          bg: "bg-emerald-50",
          border: "border-emerald-100",
          text: "text-emerald-600",
          textDark: "text-emerald-700",
          bgLight: "bg-emerald-100",
          bgDark: "bg-emerald-500",
          gradient: "from-emerald-500 via-emerald-400 to-emerald-500",
          shadow: "rgba(16, 185, 129, 0.2)",
        };

  return (
    <div className="flex-1 h-[120px]">
      <div
        className={`${colorScheme.bg} rounded-xl p-6 border ${colorScheme.border} h-[120px]`}
      >
        <div className="flex items-center justify-between h-full">
          <div className="flex flex-col gap-2 flex-1">
            {getAnalysisMessages(step, inProgress, isFailed).map(
              (message, index) => (
                <div key={index} className="flex items-center gap-6">
                  <div
                    className={
                      message.completed || message.error ? "" : "animate-spin"
                    }
                  >
                    {message.error ? (
                      <IconX className={`w-6 h-6 ${colorScheme.text}`} />
                    ) : message.completed ? (
                      <IconCheck className={`w-6 h-6 ${colorScheme.text}`} />
                    ) : (
                      <IconRefresh className={`w-6 h-6 ${colorScheme.text}`} />
                    )}
                  </div>
                  <div className="flex-1">
                    <p className={`text-sm ${colorScheme.text}`}>
                      {message.text}
                    </p>
                    {message.error && (
                      <button
                        onClick={() => refreshMutation.mutate(repoId)}
                        disabled={refreshMutation.isPending}
                        className={`
                          mt-2 inline-flex items-center gap-2 px-4 py-1.5 
                          rounded-md font-medium text-sm
                          transition-all duration-200 
                          ${
                            refreshMutation.isPending
                              ? "bg-gray-100 text-gray-400 cursor-not-allowed opacity-75"
                              : `${colorScheme.bgLight} hover:bg-red-200 hover:shadow-md 
                               active:transform active:scale-95 
                               text-red-700 cursor-pointer`
                          }
                          focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50
                        `}
                      >
                        {refreshMutation.isPending ? (
                          <>
                            <IconRefresh className="w-4 h-4 animate-spin" />
                            <span>Regenerating...</span>
                          </>
                        ) : (
                          <>
                            <IconRefresh className="w-4 h-4" />
                            <span>Try again</span>
                          </>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
          <div className="flex items-center gap-6">
            <div className="flex flex-col items-end">
              <div className="flex items-center gap-2 mb-1.5">
                <div
                  className={`h-2 w-2 rounded-full ${
                    isFailed
                      ? `${colorScheme.bgDark} animate-pulse`
                      : inProgress
                        ? `${colorScheme.bgDark} animate-pulse`
                        : colorScheme.bgDark
                  }`}
                ></div>
                <span
                  className={`text-xs ${colorScheme.textDark} font-medium ${colorScheme.bgLight} px-2 py-0.5 rounded-full`}
                >
                  {isFailed
                    ? "Failed"
                    : inProgress
                      ? "In Progress"
                      : "Completed"}
                </span>
              </div>

              <span className={`text-xs ${colorScheme.text}`}>
                {isFailed
                  ? "Analysis failed"
                  : inProgress
                    ? "Estimated time: ~2-3 min"
                    : "Completed successfully"}
              </span>
            </div>
          </div>
        </div>
      </div>

      {inProgress && (
        <div className="mt-3">
          <div className="h-2.5 bg-gray-100/50 rounded-full overflow-hidden backdrop-blur-sm shadow-[inset_0px_2px_4px_rgba(0,0,0,0.06)] relative">
            <div
              className={`h-full bg-gradient-to-r ${colorScheme.gradient} rounded-full transition-all duration-700 ease-in-out relative progress-bar`}
              style={{
                width: `${progress * 100}%`,
              }}
            >
              <div className="absolute inset-0 progress-shine" />
            </div>
            <div className="absolute inset-0 border border-black/5 rounded-full" />
          </div>
          <style>
            {`
              .progress-bar {
                background-size: 200% 100%;
                animation: shimmer 2s linear infinite;
                box-shadow: 0 0 12px ${colorScheme.shadow};
              }
              .progress-shine {
                background: linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.4) 50%, transparent 100%);
                animation: shine 1.5s infinite;
              }
              @keyframes shimmer {
                0% { background-position: 200% 0; }
                100% { background-position: -200% 0; }
              }
              @keyframes shine {
                from { transform: translateX(-100%); }
                to { transform: translateX(100%); }
              }
            `}
          </style>
        </div>
      )}
    </div>
  );
};
