import { AxiosError, AxiosResponse } from "axios";
import { instance } from "./index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuditPostType } from "../types/audits";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateFullAuditDto,
  FullAudit,
  type Error,
} from "../data/data-contracts";
import { toast } from "react-toastify";
import { API } from "../data";
import { RepositoryOverviewQueryKey } from "./repository-overview/repository-overview";

const controller = new AbortController();

export const getAuditsByRepoId = createAsyncThunk(
  "audits/getAudits",
  async (id: string, { signal }) => {
    signal.addEventListener("abort", () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.get("/audits", {
      params: { repositoryId: id },
    });
    return response.data;
  }
);

export const getAuditByAuditId = createAsyncThunk(
  "audits/getAudit",
  async (id: string, { signal }) => {
    signal.addEventListener("abort", () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.get(`/audits/${id}`);
    return response.data;
  }
);

export const postAudit = createAsyncThunk(
  "audits/postAudits",
  async (data: AuditPostType, { signal }) => {
    signal.addEventListener("abort", () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.post("/audits", data);
    return response.data;
  }
);

export const useCreateAudit = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    AxiosResponse<FullAudit>,
    AxiosError<{ error: Error }>,
    CreateFullAuditDto
  >({
    mutationFn: (data: CreateFullAuditDto) => API.audits.createFullAudit(data),
    onSuccess: () => {
      toast.success("Started full audit");
      queryClient.invalidateQueries({
        queryKey: [RepositoryOverviewQueryKey.GetOverview],
      });
    },
    onError: (error) => {
      const err = error.response?.data.error;
      toast.error(err?.payload?.message || "Failed to start full audit");
    },
  });

  return mutation;
};
