/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Error, UserStatus } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Users<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get information about the currently authenticated user
   *
   * @tags User
   * @name GetCurrentUser
   * @summary Get current user info
   * @request GET:/users/me
   * @secure
   */
  getCurrentUser = (params: RequestParams = {}) =>
    this.request<
      {
        /** @example 1 */
        id: number;
        /**
         * @format email
         * @example "user@example.com"
         */
        email: string;
        /** @example "John" */
        firstName: string | null;
        /** @example "Doe" */
        lastName: string | null;
        status: UserStatus;
      },
      Error
    >({
      path: `/users/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Request an upgrade to Premium plan
   *
   * @tags User
   * @name RequestPlanUpgrade
   * @summary Request plan upgrade
   * @request POST:/users/request-plan-upgrade
   * @secure
   */
  requestPlanUpgrade = (params: RequestParams = {}) =>
    this.request<void, Error>({
      path: `/users/request-plan-upgrade`,
      method: "POST",
      secure: true,
      ...params,
    });
}
