import { create } from "zustand";
import { ChatMessage } from "../api/websocket/useChatSocket";

interface ChatStore {
  messages: ChatMessage[];
  isLoading: boolean;
  isConnected: boolean;

  // Actions
  addMessage: (message: ChatMessage) => void;
  setMessages: (messages: ChatMessage[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsConnected: (isConnected: boolean) => void;
  clearMessages: () => void;
}

export const useChatStore = create<ChatStore>((set) => ({
  messages: [],
  isLoading: false,
  isConnected: false,

  // Add a single message to the chat
  addMessage: (message: ChatMessage) => {
    console.log("Adding message to store:", message);
    // Ensure the message has the required fields
    const validMessage: ChatMessage = {
      type: message.type || "system",
      message: message.message || (message as any).content || "Unknown message",
      timestamp: message.timestamp || new Date().toISOString(),
    };

    return set((state) => ({
      messages: [...state.messages, validMessage],
    }));
  },

  // Set all messages
  setMessages: (messages: ChatMessage[]) =>
    set({
      messages,
    }),

  // Set loading state
  setIsLoading: (isLoading: boolean) =>
    set({
      isLoading,
    }),

  // Set connection state
  setIsConnected: (isConnected: boolean) =>
    set({
      isConnected,
    }),

  // Clear all messages
  clearMessages: () =>
    set({
      messages: [],
    }),
}));
