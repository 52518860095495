import React, { useState } from "react";
import { ProjectsRepoResponseType } from "../../../types/repositories";
import { useNavigate } from "react-router-dom";
import {
  IconFolder,
  IconDotsVertical,
  IconEye,
  IconUnlink,
} from "@tabler/icons-react";
import { useDeleteRepository } from "../../../api/repositories";

interface RepositoriesTableProps {
  repositories: ProjectsRepoResponseType[];
  setSelectedRepository: React.Dispatch<
    React.SetStateAction<ProjectsRepoResponseType | undefined>
  >;
}

const RepositoriesTable: React.FC<RepositoriesTableProps> = ({
  repositories,
  setSelectedRepository,
}) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const navigate = useNavigate();

  const handleActionClick = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleClickOutside = () => {
    setOpenDropdownIndex(null);
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleRepositoryClick = (repository: ProjectsRepoResponseType) => {
    navigate(`/repository/${repository.id}/overview`);
  };

  const { mutateAsync: deleteRepository } = useDeleteRepository();

  const handleDisconnect = async (
    repository: ProjectsRepoResponseType,
    e: React.MouseEvent
  ) => {
    e.stopPropagation();
    setOpenDropdownIndex(null);
    await deleteRepository(repository.id);
  };

  return (
    <div className="w-full">
      <div className="mt-4 flow-root rounded-lg bg-white shadow-sm ring-1 ring-black/5">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-200 bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200">
              <thead>
                <tr className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200">
                  <th scope="col" className="px-6 py-5">
                    <div className="flex items-center space-x-2">
                      <span className="text-xs font-bold text-gray-500 uppercase tracking-wider">
                        Repository Name
                      </span>
                      <div className="h-4 w-px bg-gray-300"></div>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-5">
                    <div className="flex items-center space-x-2">
                      <span className="text-xs font-bold text-gray-500 uppercase tracking-wider">
                        Status
                      </span>
                      <div className="h-4 w-px bg-gray-300"></div>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-5">
                    <div className="flex items-center space-x-2">
                      <span className="text-xs font-bold text-gray-500 uppercase tracking-wider">
                        Added Date
                      </span>
                      <div className="h-4 w-px bg-gray-300"></div>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-5">
                    <div className="flex items-center space-x-2">
                      <span className="text-xs font-bold text-gray-500 uppercase tracking-wider">
                        Last Audited
                      </span>
                      <div className="h-4 w-px bg-gray-300"></div>
                    </div>
                  </th>
                  <th scope="col" className="relative px-6 py-5">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {repositories.map((repository, index) => (
                  <tr
                    key={index}
                    className="group transition-colors hover:bg-gray-50 cursor-pointer"
                    onClick={() => handleRepositoryClick(repository)}
                  >
                    <td className="whitespace-nowrap py-5 pl-8 pr-3 sm:pl-6">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-100 flex items-center justify-center group-hover:bg-gray-200">
                          <IconFolder
                            className="h-5 w-5 text-gray-500"
                            stroke={1.5}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {repository.repoName}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-5">
                      <span className="inline-flex items-center rounded-full bg-green-50 px-3 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Connected
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-6 py-5 text-sm text-gray-500">
                      {new Date().toLocaleDateString()}
                    </td>
                    <td className="whitespace-nowrap px-6 py-5 text-sm text-gray-500">
                      {repository.lastAuditDate
                        ? new Date(
                            repository.lastAuditDate
                          ).toLocaleDateString()
                        : "Not audited yet"}
                    </td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-8 text-right text-sm font-medium sm:pr-6">
                      <div
                        className="relative inline-block text-left"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          onClick={(e) => handleActionClick(e, index)}
                          className="rounded-full p-2 text-gray-400 hover:text-gray-500 focus:outline-none"
                          aria-label="More options"
                        >
                          <IconDotsVertical
                            className="w-5 h-5 transition-colors"
                            stroke={1.5}
                          />
                        </button>
                        {openDropdownIndex === index && (
                          <div className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
                            <div className="py-1" role="none">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRepositoryClick(repository);
                                }}
                                className="group flex w-full items-center px-4 py-3 text-sm text-gray-700 hover:bg-gray-50"
                              >
                                <IconEye
                                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                  stroke={1.5}
                                />
                                See Overview
                              </button>
                              <button
                                onClick={(e) => handleDisconnect(repository, e)}
                                className="group flex w-full items-center px-4 py-3 text-sm text-red-600 hover:bg-red-50"
                              >
                                <IconUnlink
                                  className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
                                  stroke={1.5}
                                />
                                Disconnect
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepositoriesTable;
