/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ChangeUserStatusRequest,
  Error,
  PaginatedUserList,
  SignInAdminRequest,
  SignInAdminResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Admins<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Authenticate admin user and get JWT token
   *
   * @tags Admin
   * @name SignInAdmin
   * @summary Sign in as admin
   * @request POST:/admins/sign-in
   */
  signInAdmin = (data: SignInAdminRequest, params: RequestParams = {}) =>
    this.request<SignInAdminResponse, Error>({
      path: `/admins/sign-in`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get paginated list of all users
   *
   * @tags Admin
   * @name GetUsers
   * @summary Get all users
   * @request GET:/admins/users
   * @secure
   */
  getUsers = (
    query?: {
      /**
       * Page number
       * @min 1
       * @default 1
       */
      page?: number;
      /**
       * Number of items per page
       * @min 1
       * @max 100
       * @default 10
       */
      limit?: number;
      /**
       * Field to sort by
       * @default "createdAt"
       */
      sortBy?: string;
      /**
       * Sort order
       * @default "asc"
       */
      sortOrder?: "asc" | "desc";
    },
    params: RequestParams = {},
  ) =>
    this.request<PaginatedUserList, Error>({
      path: `/admins/users`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Change user's status (Premium/Freemium/Premium_requested)
   *
   * @tags Admin
   * @name ChangeUserStatus
   * @summary Update user status
   * @request PATCH:/admins/users/{userId}/status
   * @secure
   */
  changeUserStatus = (userId: number, data: ChangeUserStatusRequest, params: RequestParams = {}) =>
    this.request<void, Error>({
      path: `/admins/users/${userId}/status`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Soft delete a user by setting deletedAt timestamp
   *
   * @tags Admin
   * @name DeleteUser
   * @summary Delete user
   * @request DELETE:/admins/users/{userId}
   * @secure
   */
  deleteUser = (userId: number, params: RequestParams = {}) =>
    this.request<void, Error>({
      path: `/admins/users/${userId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}
