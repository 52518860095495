import {
  IconCheck,
  IconFileDescription,
  IconAlertTriangle,
  IconBook,
  IconPackage,
  IconShieldCheck,
  IconCode,
} from "@tabler/icons-react";
import { RepositorySummary } from "../../../data/data-contracts";
import { Label } from "./Label";
import { useMemo } from "react";

interface ProjectSummaryProps {
  inProgress: boolean;
  summary: RepositorySummary | null;
}

const ProjectSummaryLoader = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full h-[160px] rounded-lg bg-gray-100 animate-pulse" />
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full h-[160px] rounded-lg bg-gray-100 animate-pulse" />
        <div className="w-full h-[160px] rounded-lg bg-gray-100 animate-pulse" />
        <div className="w-full h-[160px] rounded-lg bg-gray-100 animate-pulse" />
        <div className="w-full h-[160px] rounded-lg bg-gray-100 animate-pulse" />
      </div>
      <div className="w-full h-[120px] rounded-lg bg-gray-100 animate-pulse" />
      <div className="w-full h-[140px] rounded-lg bg-gray-100 animate-pulse" />
    </div>
  );
};

export const ProjectSummary = ({
  inProgress,
  summary,
}: ProjectSummaryProps) => {
  const renderContent = useMemo(() => {
    switch (true) {
      case inProgress:
        return <ProjectSummaryLoader />;
      case !inProgress && !summary:
        return (
          <div className="p-4 rounded-lg bg-gray-50 border border-gray-200">
            <p className="text-gray-600 text-sm">
              No summary has been generated for this repository.
            </p>
          </div>
        );
      default:
        return (
          <div className="flex flex-col gap-4">
            <div className="p-6 bg-blue-50 rounded-lg">
              <p className="text-sm text-blue-700 leading-relaxed">
                {summary?.description}
              </p>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <h4 className="font-medium text-gray-700 mb-2 flex items-center gap-2">
                  <IconPackage className="w-5 h-5 text-gray-600" />
                  Dependencies
                </h4>
                <p className="text-sm text-gray-600 leading-relaxed">
                  {summary?.dependencies}
                </p>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <h4 className="font-medium text-gray-700 mb-2 flex items-center gap-2">
                  <IconShieldCheck className="w-5 h-5 text-gray-600" />
                  Security
                </h4>
                <p className="text-sm text-gray-600 leading-relaxed">
                  {summary?.security}
                </p>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <h4 className="font-medium text-gray-700 mb-2 flex items-center gap-2">
                  <IconBook className="w-5 h-5 text-gray-600" />
                  Documentation
                </h4>
                <p className="text-sm text-gray-600 leading-relaxed">
                  {summary?.documentation}
                </p>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <h4 className="font-medium text-gray-700 mb-2 flex items-center gap-2">
                  <IconCode className="w-5 h-5 text-gray-600" />
                  Overall Quality
                </h4>
                <p className="text-sm text-gray-600 leading-relaxed">
                  {summary?.overallQuality}
                </p>
              </div>
            </div>

            <div className="p-4 bg-yellow-50 rounded-lg">
              <h4 className="font-medium text-yellow-800 mb-2 flex items-center gap-2">
                <IconAlertTriangle className="w-5 h-5 text-yellow-600" />
                Potential Risks and Areas for Improvement
              </h4>
              <p className="text-sm text-yellow-700 leading-relaxed">
                {summary?.potentialRisks}
              </p>
            </div>

            <div className="p-6 bg-green-50 rounded-lg">
              <h4 className="font-medium text-green-800 mb-3 flex items-center gap-2">
                <IconCheck className="w-5 h-5" />
                Conclusion
              </h4>
              <p className="text-sm text-green-700 leading-relaxed">
                {summary?.conclusion}
              </p>
            </div>
          </div>
        );
    }
  }, [inProgress, summary]);

  return (
    <div className="flex flex-col gap-6">
      <Label icon={IconFileDescription}>Project Analysis Summary</Label>
      {renderContent}
    </div>
  );
};
