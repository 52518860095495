import { Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";
import UserAvatar from "../UserAvatar";
import { IconLogout } from "@tabler/icons-react";
import { setLogout } from "../../store/userSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";

interface Props {
  setIsLogoutOverlayOpen: (isOpenStep1: boolean) => void;
  isLogoutOverlayOpen: boolean;
  userName: string;
}

const LogoutOverlay = (props: Props) => {
  const { isLogoutOverlayOpen, setIsLogoutOverlayOpen, userName } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <Transition appear show={isLogoutOverlayOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setIsLogoutOverlayOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-start justify-end p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[280px] transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <div className="flex items-center justify-start gap-3 h-12 border-b-2 border-custom-50 pb-4">
                  <UserAvatar userName={userName} />
                </div>
                <div className="mt-4 flex items-center justify-start">
                  <div
                    className="justify-center text-red-500 cursor-pointer flex gap-2"
                    onClick={() => {
                      dispatch(setLogout());
                      setIsLogoutOverlayOpen(false);
                      navigate("/");
                    }}
                  >
                    <IconLogout />
                    <span>Log Out</span>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LogoutOverlay;
