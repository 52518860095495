import { AxiosResponse } from "axios";
import { instance } from "./index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProjectType } from "../types/projects";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API } from "../data";
import { toast } from "react-toastify";

const controller = new AbortController();

export const postProject = createAsyncThunk(
  "projects/createProject",
  async (data: ProjectType, { signal }) => {
    signal.addEventListener("abort", () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.post("/projects", data);
    return response.data;
  }
);

export const getProject = createAsyncThunk(
  "projects/getProject",
  async (_data, { signal }) => {
    signal.addEventListener("abort", () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.get("/projects");
    return response.data;
  }
);

const queryKeys = {
  projects: ["projects"],
};

export const useProjects = () => {
  const query = useQuery({
    queryKey: queryKeys.projects,
    queryFn: () => API.projects.listProjects(),
    staleTime: 0,
    retry: false,
  });
  return query;
};

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationFn: (data: ProjectType) => API.projects.createProject(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.projects });
      toast.success("Project created successfully");
    },
    onError: (error) => {
      console.log(error, "error");
      toast.error("Failed to create project");
    },
  });
  return query;
};
