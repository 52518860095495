import { Route, Routes, useNavigate } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import AdminLoginPage from "../pages/admins/login/LoginPage";
import AdminDashboardPage from "../pages/admins/dashboard/DashboardPage";
import { useEffect } from "react";

export const AdminRoutes = () => {
  const isAdmin = !!localStorage.getItem("isAdmin");
  const navigate = useNavigate();

  useEffect(() => {
    if (isAdmin) {
      navigate("/admin/dashboard");
    } else {
      navigate("/admin/login");
    }
  }, [isAdmin, navigate]);

  return (
    <Routes>
      <Route
        path="login"
        element={
          <ProtectedRoute isAllowed={!isAdmin} redirectPath="/admin/dashboard">
            <AdminLoginPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="dashboard"
        element={
          <ProtectedRoute isAllowed={isAdmin} redirectPath="/admin/login">
            <AdminDashboardPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
