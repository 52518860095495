import { create } from "zustand";
import {
  RepositoryFile,
  RepositoryOverviewSocketEventContent,
  RepositorySummary,
  RepositoryOverviewStatus,
  RepositoryFileStatus,
} from "../data/data-contracts";

interface RepositoryOverviewStore {
  inProgress: boolean;
  isFailed: boolean;
  step: number;
  progress: number;
  stack: string[];
  files: RepositoryFile[];
  summary: RepositorySummary | null;
  status: RepositoryOverviewStatus;
  setInProgress: (inProgress: boolean) => void;
  setIsFailed: (isFailed: boolean) => void;
  updateProgress: () => void;
  reset: () => void;

  setValues: (content: RepositoryOverviewSocketEventContent) => void;
}

const sortFiles = (files: RepositoryFile[]): RepositoryFile[] => {
  return [...files].sort((a, b) => {
    // First sort by status (failed items go to bottom)
    if (
      a.status === RepositoryFileStatus.FAILED &&
      b.status !== RepositoryFileStatus.FAILED
    )
      return 1;
    if (
      a.status !== RepositoryFileStatus.FAILED &&
      b.status === RepositoryFileStatus.FAILED
    )
      return -1;

    // Then sort by id
    return (a.id ?? 0) - (b.id ?? 0);
  });
};

export const useRepositoryOverviewStore = create<RepositoryOverviewStore>(
  (set, get) => ({
    inProgress: true,
    isFailed: false,
    step: 0,
    progress: 0,
    stack: [],
    files: [],
    summary: null,
    status: RepositoryOverviewStatus.CREATED,
    setInProgress: (inProgress) => set({ inProgress }),
    setIsFailed: (isFailed) => set({ isFailed }),
    updateProgress: () => {
      const state = get();
      const { step, files } = state;

      // Weight distribution
      const STACK_WEIGHT = 0.1; // 10%
      const FILES_LIST_WEIGHT = 0.1; // 10%
      const FILE_AUDIT_WEIGHT = 0.5; // 50%
      const SUMMARY_WEIGHT = 0.3; // 30%

      let progress = 0;

      // Step 1: Stack loaded
      if (step >= 1) {
        progress += STACK_WEIGHT;
      }

      // Step 2: Files list loaded
      if (step >= 2) {
        progress += FILES_LIST_WEIGHT;
      }

      // Step 3: File audit progress
      if (files.length > 0) {
        const completedFiles = files.filter(
          (file) => file.status === RepositoryFileStatus.COMPLETED
        ).length;
        const fileProgress =
          (completedFiles / files.length) * FILE_AUDIT_WEIGHT;
        progress += fileProgress;

        // Step 4: Summary (only add if all files are completed)
        if (step >= 5) {
          progress += SUMMARY_WEIGHT;
        }
      }

      set({ progress: Math.min(progress, 1) });
    },
    reset: () => {
      set({
        stack: [],
        files: [],
        summary: null,
        status: RepositoryOverviewStatus.IN_PROGRESS,
        inProgress: true,
        isFailed: false,
        step: 0,
        progress: 0,
      });
    },
    setValues: (content: RepositoryOverviewSocketEventContent) => {
      const { stack, files, summary, status, step } = content;
      if (status === RepositoryOverviewStatus.IN_PROGRESS) {
        set({
          inProgress: true,
        });
      }

      set({
        step,
        stack,
        files: sortFiles(files),
        summary,
        status,
      });

      get().updateProgress();
    },
  })
);
