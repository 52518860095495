import React, { useRef, useState } from "react";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { IsignIn } from "../types/auth";
import { signin } from "../api/auth";
import { setEmail, setLogged } from "../store/userSlice";
import { AppDispatch } from "../store";
import Logo from "../components/fields/Logo";

const LoginPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputRef = useRef<HTMLInputElement | null>(null);

  const onSubmit = (data: IsignIn) => {
    console.log("@@@ click");

    signin(data)
      .then((res) => {
        console.log("@@@ res", res);

        if (res) {
          dispatch(setEmail(res.email));
          dispatch(setLogged());
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const schema = yup
    .object({
      email: yup
        .string()
        .email("Email should be valid")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    })
    .required();

  type Inputs = {
    email: string;
    password: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onBlur",
  });
  const { ref, ...rest } = register("password");

  const togglePasswordVisibility = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    if (passwordInputRef?.current) {
      passwordInputRef.current.focus();
    }
  };

  console.log("@@@", isValid, isDirty, !isValid || !isDirty);
  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-start  font-['Inter'] text-custom-900 p-4">
      <div className="text-2xl font-bold mt-20 p-5">
        <Logo />
      </div>
      <div className="text-3xl font-bold mt-20">Welcome back!</div>
      <p className="text-custom-400 mb-6">Log in to your account</p>
      <div className="mt-10 w-full max-w-md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email" className="text-custom-900 text-sm">
            Email:
          </label>
          <input
            id="email"
            type="email"
            placeholder="Enter your email"
            className="w-full p-2 border rounded-md bg-white"
            {...register("email", { required: true })}
          />
          {errors.email ? (
            <div className="text-red-500 text-xs h-4">
              {errors.email.message}
            </div>
          ) : (
            <div className="h-4"></div>
          )}
          <div className="relative mt-2">
            <label htmlFor="password" className="text-custom-900 text-sm">
              Password:
            </label>
            <input
              {...rest}
              id="password"
              ref={(e) => {
                ref(e);
                passwordInputRef.current = e;
              }}
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              className="w-full p-2 border rounded-md bg-white"
            />
            {errors.password ? (
              <div className="text-red-500 text-xs h-4">
                {errors.password.message}
              </div>
            ) : (
              <div className="h-4"></div>
            )}
            <button
              className="absolute top-8 right-2 hover:text-custom-900 focus:outline-none"
              onClick={(e) => togglePasswordVisibility(e)}
            >
              {showPassword ? <IconEyeOff size={24} /> : <IconEye size={24} />}
            </button>
          </div>
          <button
            disabled={!isValid || !isDirty}
            type="submit"
            className={[
              isValid && isDirty
                ? "bg-custom-900 hover:bg-gray-800"
                : "bg-custom-300",
              "text-white w-full p-2 rounded-md mt-6",
            ].join(" ")}
          >
            Login
          </button>
        </form>
      </div>
      <p className="text-sm mt-20 text-center">
        <span>Don't have an account? </span>
        <Link
          to="/signup"
          className="text-custom-900 font-bold hover:text-ccustom-900"
        >
          Sign Up
        </Link>
      </p>
    </div>
  );
};

export default LoginPage;
