import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";
import { useChatStore } from "../../store/chatStore";

// Define socket event types
export enum ChatSocketEvent {
  CONNECT = "CONNECT",
  DISCONNECT = "DISCONNECT",
  MESSAGE = "message",
  TYPING = "typing",
  ERROR = "error",
}

// Message structure
export interface ChatMessage {
  type: "user" | "system" | "error";
  message: string;
  timestamp: string;
}

// Socket message structure
export interface ChatSocketEventMessage {
  type: ChatSocketEvent;
  content: ChatMessage | string | any;
  timestamp: string;
}

/**
 * @description Connect to the repository chat socket
 * @param repositoryId - The id of the repository
 * @returns The socket messaging functionality
 */
export const useChatSocket = (repositoryId: string) => {
  // Track connecting state
  const [isConnecting, setIsConnecting] = useState(true);

  // Access the chat store
  const {
    messages,
    isLoading,
    isConnected,
    addMessage,
    setIsLoading,
    setIsConnected,
  } = useChatStore();

  // In a real implementation, this would be properly typed and from Redux
  const token = useSelector((state: any) => {
    if (typeof state.user === "object" && state.user !== null) {
      return state.user.token || "";
    }
    return "";
  });

  // WebSocket configuration
  const ws = {
    URL: `wss://code-audit-web-backend-940d30d62852.herokuapp.com/ws/repository-chat/${repositoryId}`,

    connect: (authToken: string | null) => {
      return {
        type: ChatSocketEvent.CONNECT,
        data: { repositoryId, authToken },
      };
    },

    disconnect: () => {
      return { type: ChatSocketEvent.DISCONNECT };
    },

    sendMessage: (content: string) => {
      return {
        message: content,
      };
    },
  };

  const wsRef = useRef(ws);

  // Setup WebSocket connection
  const { lastJsonMessage, sendJsonMessage } = useWebSocket(wsRef.current.URL, {
    share: false,
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onOpen: () => {
      // Add a delay to demonstrate the connecting spinner for 2 seconds
      setTimeout(() => {
        setIsConnected(true);
        setIsConnecting(false);

        // Send auth message on connection if needed
        if (token) {
          try {
            sendJsonMessage({
              type: "AUTH",
              token,
            });
            console.log("### Auth message sent");
          } catch (error) {
            console.error("Failed to send auth message:", error);
          }
        }
      }, 2000); // 2 second delay
    },
    onClose: () => {
      console.log("### Chat WS Disconnected");
      setIsConnected(false);
      // Set connecting to true when attempting to reconnect
      setIsConnecting(true);

      // Show reconnecting state for at least 2 seconds
      setTimeout(() => {
        // Only keep reconnecting state if we're still not connected
        if (!isConnected) {
          setIsConnecting(true);
        }
      }, 2000);
    },
    onError: (event) => {
      console.error("### Chat WS Error", event);
      setIsConnected(false);
      setIsConnecting(false);
    },
    onMessage: (event) => {
      console.log("### Raw WS message received:", event.data);
      try {
        // Try to parse the message to see its structure
        const parsed = JSON.parse(event.data);
        console.log("### Parsed message structure:", Object.keys(parsed));
      } catch (e) {
        console.log("### Could not parse message as JSON");
      }
    },
  });

  // Process received messages
  const message = lastJsonMessage as ChatSocketEventMessage;

  useEffect(() => {
    if (message) {
      console.log("### Chat WS Message received:", message);

      switch (message.type) {
        case ChatSocketEvent.CONNECT:
          setIsConnected(true);
          break;

        case ChatSocketEvent.MESSAGE:
          try {
            const adaptedMessage = message as ChatSocketEventMessage;

            console.log("### Adapted message:", message, adaptedMessage);
            const agentMessage = adaptedMessage.content;
            const timestamp = adaptedMessage.timestamp;

            addMessage({
              type: "system",
              message: agentMessage,
              timestamp: timestamp || new Date().toISOString(),
            });
            setIsLoading(false);
          } catch (error) {
            addMessage({
              type: "error",
              message: "Something went wrong",
              timestamp: new Date().toISOString(),
            });
            setIsLoading(false);
          }
          break;

        case ChatSocketEvent.ERROR:
          console.error("### Error from server:", message.content);
          addMessage({
            type: "error",
            message: `Error: ${message.content}. Please try again.`,
            timestamp: new Date().toISOString(),
          });
          setIsLoading(false);
          break;

        default:
      }
    }
  }, [lastJsonMessage, message, addMessage, setIsLoading, setIsConnected]);

  // Send a message
  const sendMessage = (content: string) => {
    if (content.trim()) {
      const message: ChatMessage = {
        type: "user",
        message: content,
        timestamp: new Date().toISOString(),
      };

      // Add the message to store
      addMessage(message);

      // Send to server
      try {
        sendJsonMessage(wsRef.current.sendMessage(content));
        setIsLoading(true);
      } catch (error) {
        // Show error message
        addMessage({
          type: "error",
          message: "Failed to send message. Please try again.",
          timestamp: new Date().toISOString(),
        });
      }
    }
  };

  return {
    messages,
    sendMessage,
    isConnected,
    isLoading,
    isConnecting,
  };
};
