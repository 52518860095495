import axios, { AxiosError, AxiosResponse } from "axios";
import { instance } from "./index";
import { TPostCodeSuccess } from "../types/auth";
import { CodeType } from "../types/users";
import {
  PostRepoDataType,
  PostRepoResponseType,
  RepoIdType,
  RepoResponseType,
} from "../types/repositories";
import { ProjectIdType } from "../types/projects";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { API } from "../data";
import { RepositoryOverviewQueryKey } from "./repository-overview/repository-overview";

export const useGitHubCode = (code: string | null) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["github-code"],
    queryFn: () => API.remoteRepos.authGithub({ code: code as string }),
    enabled: !!code,
  });

  return { data, error, isLoading };
};

export const useGitHubRepo = (success: boolean | null, projectId: number) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["github-repositories"],
    queryFn: () => API.remoteRepos.listGithubRepositories({ projectId }),
    enabled: !!projectId && !!success,
  });

  return { data, error, isLoading };
};

export const postGithubCode = (
  data: CodeType
): Promise<TPostCodeSuccess | void> => {
  return instance
    .post("/remote-repos/github/auth", data)
    .then(function (response: AxiosResponse<TPostCodeSuccess>) {
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, "error");
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const getGithubRepo = (
  projectId: number
): Promise<RepoResponseType[] | void> => {
  return instance
    .get("/remote-repos/github/repositories", {
      params: { projectId },
    })
    .then(function (response: AxiosResponse<RepoResponseType[]>) {
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, "error");
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const getAvailableRemotes = (): Promise<string[] | void> => {
  return instance
    .get("/remote-repos/available-remotes")
    .then(function (response: AxiosResponse<string[]>) {
      return response.data as string[];
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, "error");
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const postRepositories = (
  data: PostRepoDataType
): Promise<PostRepoResponseType | void> => {
  return instance
    .post("/repositories", data)
    .then(function (response: AxiosResponse<PostRepoResponseType>) {
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, "error");
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const getLanguages = (): Promise<string[] | void> => {
  return instance
    .get("/available-languages")
    .then(function (response: AxiosResponse<string[]>) {
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, "error");
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

export const deleteRepo = (data: RepoIdType): Promise<string[] | void> => {
  const id = data.id;
  return instance
    .delete(`/repositories/${id}`)
    .then(function (response: AxiosResponse<string[]>) {
      return response.data;
    })
    .catch(function (error: Error | AxiosError) {
      if (axios.isAxiosError(error)) {
        console.log(error, "error");
        const message = error?.response?.data.error;
        throw new Error(message);
      }
    });
};

const controller = new AbortController();

export const getProjectsRepo = createAsyncThunk(
  "repositories/getProjectsRepo",
  async (data: ProjectIdType, { signal }) => {
    const projectId = data.projectId;
    signal.addEventListener("abort", () => {
      controller.abort();
    });
    const response: AxiosResponse = await instance.get("/repositories", {
      data: { projectId },
    });
    return response.data;
  }
);

enum RepositoriesQueryKeys {
  ALL_REPOSITORIES = "repositories",
  REPOSITORY_LANGUAGE = "repositoryLanguage",
  VECTOR_REPOSITORY = "vectorRepository",
}

export const useGetAllRepositories = (projectId: number) => {
  const { data, error, isLoading } = useQuery({
    queryKey: [RepositoriesQueryKeys.ALL_REPOSITORIES, projectId],
    queryFn: () => API.repositories.repositoriesList({ projectId }),
    enabled: !!projectId,
  });

  return { repositories: data?.data || [], error, isLoading };
};

export const useDeleteRepository = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => API.repositories.repositoriesDelete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RepositoriesQueryKeys.ALL_REPOSITORIES],
      });
      toast.success("Repository disconnected successfully");
    },
    onError: () => {
      toast.error("Failed to disconnect repository");
    },
  });
};

export const useGetRepositoryLanguage = (repoId: number, enabled: boolean) => {
  const { data, error, isLoading } = useQuery({
    queryKey: [RepositoriesQueryKeys.REPOSITORY_LANGUAGE, repoId],
    queryFn: () => API.repositories.primaryLanguageDetail(repoId),
    enabled: !!repoId && enabled,
  });

  return { language: data?.data?.language || null, error, isLoading };
};

export const useVectorRepository = (repoId: number, needPoll: boolean) => {
  const { data, error, isLoading } = useQuery({
    queryKey: [RepositoriesQueryKeys.VECTOR_REPOSITORY, repoId],
    queryFn: () => API.repositories.getVectorRepository(repoId),
    enabled: !!repoId,
    staleTime: 0,
    refetchInterval: !!repoId && needPoll ? 5000 : false,
    refetchIntervalInBackground: true,
  });

  return { vectorRepository: data?.data || null, error, isLoading };
};

export const useVectorRepositorySync = (repoId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => API.repositories.syncVectorRepository(repoId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RepositoryOverviewQueryKey.GetOverview, repoId],
        exact: true,
      });

      queryClient.invalidateQueries({
        queryKey: [RepositoriesQueryKeys.VECTOR_REPOSITORY, repoId],
        exact: true,
      });

      queryClient.refetchQueries({
        queryKey: [RepositoriesQueryKeys.VECTOR_REPOSITORY, repoId],
        exact: true,
      });

      toast.success("Start vector repository sync successfully");
    },
    onError: () => {
      toast.error("Failed to start vector repository sync");
    },
  });
};
