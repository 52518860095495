import { create } from "zustand";
import { User, UserStatus } from "../data/data-contracts";

interface UserStore {
  // State
  user: User | null;
  isLoading: boolean;
  error: string | null;

  // Actions
  setUser: (user: User | null) => void;
  setLoading: (isLoading: boolean) => void;
  setError: (error: string | null) => void;
  updateUserStatus: (status: UserStatus) => void;
  clearUser: () => void;
}

export const useUserStore = create<UserStore>((set) => ({
  // Initial state
  user: null,
  isLoading: true,
  error: null,

  // Actions
  setUser: (user) => set({ user, error: null }),
  setLoading: (isLoading) => set({ isLoading }),
  setError: (error) => set({ error }),
  updateUserStatus: (status) =>
    set((state) => ({
      user: state.user ? { ...state.user, status } : null,
    })),
  clearUser: () => set({ user: null, error: null }),
}));
