import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import { useNavigate, useParams } from "react-router-dom";
import { getAuditsByRepoId } from "../api/audits";
import { AuditResponseType } from "../types/audits";
import { IconArrowLeft, IconEye } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import LoadingSpinner from "../components/fields/LoadingSpinner";

const HistoryPage = () => {
  const { id } = useParams();
  const [audit, setAudit] = useState<AuditResponseType[]>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  useEffect(() => {
    setIsFetchingData(true);
    dispatch(getAuditsByRepoId(id as string))
      .then((res) => {
        setAudit(res.payload as AuditResponseType[]);
      })
      .catch((err) => {
        console.log(err, "err");
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  }, [dispatch, id]);

  const handleGoBack = () => {
    navigate(`/repository/${id}/overview`);
  };

  return (
    <div className="bg-white min-h-screen flex items-stretch justify-start font-['Inter'] text-custom-900">
      <div className="mx-10 my-2 h-full w-full flex flex-col justify-start items-center">
        <TopBar />
        <button
          onClick={handleGoBack}
          className="text-custom-900 hover:font-bold mb-4 flex w-full items-center gap-2 justify-start"
        >
          <IconArrowLeft size={20} />
          Back to Dashboard
        </button>
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left">
                Audit date
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left">
                Audit time
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left">
                Supported language
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {!!audit?.length &&
              audit.map((audit) => {
                const updatedAt = new Date(audit.updatedAt);
                const date = updatedAt.toLocaleDateString();
                const time = updatedAt.toLocaleTimeString();
                const isFinished =
                  audit.status === "Completed" || audit.status === "Failed";

                return (
                  <tr
                    key={audit.id}
                    className={`hover:bg-gray-100 relative ${isFinished ? "cursor-pointer" : ""}`}
                    onClick={() =>
                      isFinished && navigate(`/report/${audit.id}`)
                    }
                  >
                    <td className="border-b border-gray-200">
                      {!audit.checked && audit.status !== "InProgress" && (
                        <Tippy content={"This audit is unchecked"}>
                          <div className="absolute left-0 top-3.5 w-3 h-3 bg-green-400 rounded-full"></div>
                        </Tippy>
                      )}
                      <span className="py-2 px-4">{date}</span>
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">
                      {time}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">
                      {audit.language}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 relative">
                      <div className="flex gap-6 justify-start items-center">
                        <span>{audit.status}</span>
                        {isFinished && (
                          <IconEye
                            size={20}
                            className="text-custom-400 absolute left-28 lg:left-40"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {(isFetchingData || !audit) && <LoadingSpinner />}
        {!!audit && audit?.length === 0 && !isFetchingData && (
          <div className="w-full text-center py-10 flex justify-center items-center">
            There are no audits yet
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryPage;
