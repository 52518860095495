import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import CreateAccountPage from "../pages/CreateAccountPage";
import LoginPage from "../pages/LoginPage";
import CreateAccountPage2 from "../pages/CreateAccountPage2";
import ReportPage from "../pages/ReportPage";
import useAuth from "../hooks/UseAuth";
import HistoryPage from "../pages/HistoryPage";
import { RepositoryOverviewPage } from "../pages/repository-overview/RepositoryOverviewPage";
import NotFoundPage from "../pages/NotFoundPage";
import ErrorPage from "../pages/ErrorPage";
import { AdminRoutes } from "./AdminRoutes";
import { useGetMe } from "../api/users";
import { useUserStore } from "../store/userStore";
import { useEffect, useState } from "react";
import CodeAuditPage from "../pages/repositories/CodeAuditPage";

export default function Router() {
  const isLogged = useAuth();
  useGetMe();

  const { user, isLoading } = useUserStore();

  const token = localStorage.getItem("token");

  const [isAllowed, setIsAllowed] = useState(true);

  useEffect(() => {
    if (token && user) {
      setIsAllowed(true);
    } else {
      if (isLoading) {
        setIsAllowed(true);
      } else {
        setIsAllowed(false);
      }
    }
  }, [token, user, isLoading]);

  return (
    <Routes>
      <Route path="/not-found" Component={NotFoundPage} />
      <Route path="/something-went-wrong" Component={ErrorPage} />

      <Route
        element={<ProtectedRoute isAllowed={isLogged} redirectPath="/signin" />}
      >
        <Route path="*" Component={CodeAuditPage} />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={isAllowed} redirectPath="/signin" />
        }
      >
        <Route path="/code-audits-history/:id" Component={HistoryPage} />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={!isLogged} redirectPath="/dashboard" />
        }
      >
        <Route path="/signin" Component={LoginPage} />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={!isLogged} redirectPath="/signup2" />
        }
      >
        <Route path="/signup" Component={CreateAccountPage} />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={isLogged} redirectPath="/dashboard" />
        }
      >
        <Route path="/signup2" Component={CreateAccountPage2} />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={isAllowed} redirectPath="/signin" />
        }
      >
        <Route
          path="/repository/:id/overview"
          Component={RepositoryOverviewPage}
        />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={isAllowed} redirectPath="/signin" />
        }
      >
        <Route path="/dashboard" Component={CodeAuditPage} />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={isAllowed} redirectPath="/signin" />
        }
      >
        <Route path="/report/:auditId" Component={ReportPage} />
      </Route>

      {/* Admin Routes */}
      <Route path="/admin/*" element={<AdminRoutes />} />

      <Route path="*" Component={NotFoundPage} />
    </Routes>
  );
}
