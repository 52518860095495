import React from "react";
import { ReportType, ErrorObject } from "../../types/general";
import ReportTabDownloadReport from "./ReportTabDownloadReport";
import { useState } from "react";
import {
  IconChevronDown,
  IconAlertCircle,
  IconInfoCircle,
} from "@tabler/icons-react";

interface Props {
  report: ReportType;
  updated: Date;
}

export default function ReportTabLicenses(props: Props) {
  const { report, updated } = props;
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const toggleRow = (license: string) => {
    if (expandedRows.includes(license)) {
      setExpandedRows(expandedRows.filter((row) => row !== license));
    } else {
      setExpandedRows([...expandedRows, license]);
    }
  };

  return (
    <>
      <ReportTabDownloadReport updated={updated} />
      <table className="min-w-full divide-y divide-custom-50">
        <thead className="">
          <tr>
            <th className="px-6 py-3 text-left text-base leading-7 font-medium text-custom-400 tracking-wider">
              License
            </th>
            <th className="px-6 py-3 text-left text-base leading-7 font-medium text-custom-400 tracking-wider">
              Number of Packages
            </th>
          </tr>
        </thead>
        <tbody className="bg-white border-custom-50 border divide-y divide-custom-50">
          {!report.licenseCheck?.hasOwnProperty("error") ? (
            Object.entries(report.licenseCheck).map(([license, data]) => (
              <React.Fragment key={license}>
                <tr
                  onClick={() => toggleRow(license)}
                  style={{ borderBottom: "1px solid #E8ECEF" }}
                >
                  <td
                    className="px-6 py-4 font-medium text-custom-900 text-base leading-7 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleRow(license);
                    }}
                  >
                    <span>{license}</span>
                    <IconChevronDown
                      className={`${
                        expandedRows.includes(license)
                          ? "transform rotate-180"
                          : ""
                      } inline-block ml-2 transition-transform duration-200 h-5 w-5`}
                    />
                    {expandedRows.includes(license) && (
                      <div className="">
                        <ul>
                          {data.packages.map((item) => (
                            <li key={item} className="px-4 pt-2 text-sm">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 font-normal text-custom-900 text-base leading-7">
                    {data.totalCount}
                  </td>
                </tr>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={2} className="px-6 py-4">
                {(report.licenseCheck as unknown as ErrorObject).error ===
                "Language is not supported for License Check" ? (
                  <div className="flex items-start gap-3 p-4 bg-blue-50 rounded-lg border border-blue-100">
                    <IconInfoCircle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
                    <div>
                      <div className="font-medium text-blue-700 mb-1">
                        Language Not Supported
                      </div>
                      <div className="text-sm text-blue-600">
                        License detection is not supported for this programming
                        language.
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-start gap-3 p-4 bg-red-50 rounded-lg border border-red-100">
                    <IconAlertCircle className="w-5 h-5 text-red-500 flex-shrink-0 mt-0.5" />
                    <div>
                      <div className="font-medium text-red-700 mb-1">
                        Error Occurred
                      </div>
                      <div className="text-sm text-red-600">
                        {(report.licenseCheck as unknown as ErrorObject)
                          .error ||
                          "An error occurred while loading license information. Please try again later."}
                      </div>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
