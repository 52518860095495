/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Error, GithubAuthRequest, GithubAuthResponse, ListGithubRepositoriesResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class RemoteRepos<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Authenticate user with GitHub using authorization code
   *
   * @tags GitHub
   * @name AuthGithub
   * @summary Authenticate with GitHub
   * @request POST:/remote-repos/github/auth
   * @secure
   */
  authGithub = (data: GithubAuthRequest, params: RequestParams = {}) =>
    this.request<GithubAuthResponse, Error>({
      path: `/remote-repos/github/auth`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description List available repositories of the authenticated user from GitHub
   *
   * @tags GitHub
   * @name ListGithubRepositories
   * @summary List GitHub repositories
   * @request GET:/remote-repos/github/repositories
   * @secure
   */
  listGithubRepositories = (
    query: {
      /**
       * ID of the project to check repository connections
       * @format int64
       */
      projectId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ListGithubRepositoriesResponse, Error>({
      path: `/remote-repos/github/repositories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
