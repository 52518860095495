/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Error {
  /** The HTTP status code */
  statusCode: number;
  payload: {
    /** The HTTP status code within the payload */
    statusCode: number;
    /** Error type */
    error: string;
    /** Error message */
    message: string;
    validation?: {
      /** The source of the validation error */
      source?: string;
      keys?: string[];
    };
  };
  headers: Record<string, any>;
}

/** Pagination meta data */
export interface PaginationMetadata {
  /**
   * @min 1
   * @example 1
   */
  currentPage: number;
  /** @example 100 */
  totalCount: number;
  /** @example 10 */
  totalPages: number;
  /** @example true */
  hasNext: boolean;
  /** @example false */
  hasPrev: boolean;
  /**
   * Limit items
   * @example 10
   */
  perPage: number;
}

export interface SignInAdminRequest {
  /**
   * @format email
   * @example "admin@example.com"
   */
  email: string;
  /**
   * @format password
   * @example "********"
   */
  password: string;
}

export interface SignInAdminResponse {
  /**
   * @format email
   * @example "admin@example.com"
   */
  email: string;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9..." */
  authToken: string;
}

export enum UserStatus {
  Premium = "Premium",
  Freemium = "Freemium",
  PremiumRequested = "Premium_requested",
}

export interface ChangeUserStatusRequest {
  status: UserStatus;
}

export interface User {
  /** @example 1 */
  id: number;
  /**
   * @format email
   * @example "user@example.com"
   */
  email: string;
  /** @example "John" */
  firstName?: string | null;
  /** @example "Doe" */
  lastName?: string | null;
  status: UserStatus;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export interface PaginatedUserList {
  data: User[];
  /** Pagination meta data */
  meta: PaginationMetadata;
}

export type UserMe = any;

export interface UpdateUserRequest {
  /**
   * @format email
   * @example "user@example.com"
   */
  email?: string;
  /** @example "John" */
  firstName?: string;
  /** @example "Doe" */
  lastName?: string;
}

export interface UpdateUserResponse {
  /** @example 1 */
  id: number;
  /**
   * @format email
   * @example "user@example.com"
   */
  email: string;
  /** @example "John" */
  firstName: string | null;
  /** @example "Doe" */
  lastName: string | null;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9..." */
  authToken: string;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9..." */
  refreshToken: string;
}

export interface Project {
  /**
   * The project ID
   * @example 1
   */
  id: number;
  /**
   * The project name
   * @example "My Project"
   */
  name: string;
}

export interface CreateProjectRequest {
  /**
   * The name of the project to create
   * @example "New Project"
   */
  name: string;
}

export interface CreateProjectResponse {
  /**
   * The ID of the created project
   * @example 1
   */
  id: number;
  /**
   * The name of the created project
   * @example "New Project"
   */
  name: string;
}

export type ListProjectsResponse = Project[];

export interface Repository {
  /** @example 1 */
  id: number;
  /** @example "owner/repo-name" */
  repoName: string;
  /** @example 1 */
  projectId: number;
  language?: string | null;
  /** @example "123456789" */
  githubId: string;
  /** @example 1 */
  userId: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
}

export interface CreateRepositoryRequest {
  /** @example "123456789" */
  githubId: string;
  /** @example 1 */
  projectId: number;
  /** @example "owner/repo-name" */
  repoName: string;
}

export type ListRepositoriesResponse = {
  /** @example 1 */
  id: number;
  /** @example "owner/repo-name" */
  repoName: string;
  auditStatus: "InProgress" | "Completed" | "Failed" | null;
  /** @format date-time */
  lastAuditDate: string | null;
  /** @example 0 */
  uncheckedAudits: number;
}[];

export interface VectorRepository {
  /** @example 1 */
  id?: number;
  status?: VectorRepositoryStatus;
}

export interface GithubAuthRequest {
  /** GitHub authorization code */
  code: string;
}

export interface GithubAuthResponse {
  /** Indicates if GitHub authentication was successful */
  success: boolean;
}

export interface GithubRepository {
  /**
   * GitHub repository ID
   * @format int64
   */
  id: number;
  /** GitHub repository name */
  name: string;
  /** Indicates if the repository is already connected to the project */
  connected: boolean;
}

/** List of GitHub repositories available to the user */
export type ListGithubRepositoriesResponse = GithubRepository[];

export enum RepositoryOverviewSocketEvent {
  CONNECT = "CONNECT",
  PENDING = "PENDING",
  DISCONNECT = "DISCONNECT",
  OVERVIEW_STARTED = "OVERVIEW_STARTED",
  GET_STACK_AND_FILES = "GET_STACK_AND_FILES",
  GET_FILES_OVERVIEW = "GET_FILES_OVERVIEW",
  GET_SUMMARY = "GET_SUMMARY",
  OVERVIEW_COMPLETED = "OVERVIEW_COMPLETED",
  OVERVIEW_FAILED = "OVERVIEW_FAILED",
}

export interface RepositoryOverviewSocketEventContent {
  /**
   * The current step of the overview process
   * @example 1
   */
  step: number;
  /** @example ["React","Next.js","Tailwind CSS"] */
  stack: string[];
  /** @example [{"id":1,"name":"index.js","status":"COMPLETED","overview":"This is a summary of the file"}] */
  files: RepositoryFile[];
  /**
   * The summary of the project
   * @example {"description":"This is a summary of the project","dependencies":"This is a list of dependencies","security":"This is a list of security measures","documentation":"This is a list of documentation","overallQuality":"This is a list of overall quality","potentialRisks":"This is a list of potential risks","conclusion":"This is a conclusion of the project"}
   */
  summary?: RepositorySummary;
  /**
   * The status of the overview process
   * @example "IN_PROGRESS"
   */
  status: RepositoryOverviewStatus;
}

export interface RepositoryOverviewSocketEventMessage {
  type: RepositoryOverviewSocketEvent;
  content: RepositoryOverviewSocketEventContent;
}

export enum RepositoryOverviewStatus {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum RepositoryFileStatus {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export interface RepositoryFile {
  /**
   * The id of the file
   * @example 1
   */
  id: number;
  /**
   * The name of the file
   * @example "index.js"
   */
  name: string;
  status: RepositoryFileStatus;
  /**
   * The reason the file failed
   * @example "This is a reason the file failed"
   */
  failedReason?: string;
  /**
   * The overview of the file
   * @example "This is a summary of the file"
   */
  overview: string;
}

export interface RepositorySummary {
  /** The overview of the project and what purpose it serves */
  description: string;
  /** The list of dependencies used in the project and what they are used for */
  dependencies: string;
  /** The security measures and how they are implemented */
  security: string;
  /** The documentation and how it is organized */
  documentation: string;
  /** The overall quality and how it is evaluated */
  overallQuality: string;
  /** The potential risks and how they are evaluated */
  potentialRisks: string;
  /** The conclusion of the project summary */
  conclusion: string;
}

export interface RepositoryOverview {
  /**
   * The id of the repository
   * @example 1
   */
  id: number;
  /**
   * The name of the repository
   * @example "example-repo"
   */
  repoName: string;
  stack: string[];
  /**
   * The summary of the repository
   * @example "This is a summary of the repository"
   */
  summary?: RepositorySummary;
  /**
   * The files of the repository
   * @example [{"id":1,"name":"index.js","status":"COMPLETED","overview":"This is a summary of the file"}]
   */
  files: RepositoryFile[];
  /**
   * The status of the repository overview
   * @example "IN_PROGRESS"
   */
  status: RepositoryOverviewStatus;
  /** The reason the repository overview failed */
  failedReason?: string;
  /**
   * The status of the audit
   * @example "Completed"
   */
  auditStatus?: AuditStatus | null;
  /** The date of the last audit */
  lastAuditDate?: string | null;
  /**
   * The audits of the repository
   * @example [{"id":1,"status":"Completed","createdAt":"2021-01-01"}]
   */
  audits?: Audit[] | null;
  vectorRepository?: {
    /**
     * The id of the vector repository
     * @example 1
     */
    id?: number;
    /**
     * The status of the vector repository
     * @example "IN_PROGRESS"
     */
    status?: VectorRepositoryStatus;
  };
}

export interface RepositoryPrimaryLanguageResponse {
  language?: string | null;
}

export enum VectorRepositoryStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum AuditStatus {
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed",
}

export interface Audit {
  /**
   * The id of the audit
   * @example 1
   */
  id: number;
  status: AuditStatus;
  /**
   * The date of the audit
   * @example "2021-01-01"
   */
  createdAt: string;
}

export interface CreateFullAuditDto {
  /**
   * The programming language for the audit
   * @example "javascript"
   */
  language: string;
  /**
   * The ID of the repository to audit
   * @example 1
   */
  repositoryId: number;
}

export interface FullAudit {
  /**
   * The ID of the audit
   * @example 1
   */
  id: number;
  status: AuditStatus;
  /**
   * The date and time the audit was created
   * @example "2021-01-01T00:00:00Z"
   */
  updatedAt: string;
  /**
   * The programming language for the audit
   * @example "javascript"
   */
  language: string;
  /**
   * The URL of the audit report
   * @example "https://audit.com/report"
   */
  reportUrl: string;
  /**
   * The body of the audit
   * @example "The audit body"
   */
  body?: string;
  /**
   * Whether the audit has been checked
   * @example true
   */
  checked: boolean;
}
